<template>
    <div class="partners">
        <h2 class="partners__title h2" v-html="title"></h2>
        <ul class="partners__list">
            <li v-for="item in list" class="partners__item">
                <img :src="getImgUrl(item.img)" class="partners__img"/>
                <p class="partners__desc" v-html="item.desc"></p>
            </li>

        </ul>
        <button class="partners__btn" @click="openPopup" v-html="btn"></button>
        <Popup/>
    </div>
</template>

<script setup>
import Popup from "../../../components/Popup"
import {useStore} from "vuex";
import {computed} from "vue";
import {blockBody} from "@/scripts/utils";

const name = "Partners";
const store = useStore();

const data = store.state.currentData;
const title = computed(() => data.main.partners.title);
const list = computed(() => data.main.partners.list);
const btn = computed(() => data.main.partners.btn);
const imgNumber = 9

// const getImgUrl = (index) => {
//     return require(`../../../assets/images/logos/logo${index}.png`);
// };

const getImgUrl = (pic) => {
    return require('../../../assets/images/logos/' + pic);
};

const openPopup = () => {
    blockBody()
    store.commit('togglePopup');
}
</script>

<style lang="scss">
.partners {
    padding-top: 100px;

    @media screen and (max-width: $mobile) {
        padding-top: 60px;
    }

    &__list {
        width: 100%;
        list-style: none;
        padding: 0;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 380px;
        box-sizing: border-box;
        margin-bottom: 80px;

        @media screen and (max-width: $mobile) {
            width: 100%;
            margin-bottom: 40px;
        }
    }

    &__img {
        display: block;
        width: 230px;
        height: 230px;
        margin-bottom: 10px;

        @media screen and (max-width: $mobile) {
            width: 180px;
            height: 180px;
            margin-bottom: 0;
        }
    }

    &__desc {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: #2C2C2C;

        @media screen and (max-width: $mobile) {
            font-size: 18px;
            line-height: 24px;
        }
    }

    &__btn {
        display: block;
        width: 255px;
        height: 60px;
        padding: 0;
        background-color: #052262;
        border-radius: 8px;
        border: none;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #FFFFFF;
        margin: 0 auto;
        transition: all .3s ease;
        cursor: pointer;
        outline: none;

        @media screen and (max-width: $mobile) {
            width: 100%;
            height: 50px;
            font-size: 18px;
            line-height: 26px;

            &:hover {
                background-color: #052262;
            }
        }

        &:hover {
            background-color: lighten(#052262, 10%);
        }
    }
}
</style>