<template>
    <form class="seminar-registration"
          id="reg"
          enctype="multipart/form-data"
          autocomplete="off"
          method="POST"
          novalidate
          v-on:submit.prevent="onSubmit">
        <div class="seminar-registration__main">
            <div class="seminar-registration__wrapper">
                <FormInput
                    :input-spec="surnameSpec"
                    @input="validateSurname"/>
                <FormInput
                    :input-spec="nameSpec"
                    @input="validateName"/>

                <FormInput
                    :input-spec="emailSpec"
                    @input="validateEmail"
                />
                <FormInput
                    :input-spec="phoneSpec"
                    @input="validatePhone"
                />

                <FormInput
                    :input-spec="companySpec"
                />

                <FormInput
                    :input-spec="positionSpec"
                />
            </div>
            <div class="seminar-registration__checkbox">
                <Checkbox
                    :text="checkboxText"
                    :active="agreeActive"
                    @click="agreeCheckboxClick"/>
            </div>
            <button class="seminar-registration__submit btn btn-orange" type="submit" :class="{'_disabled': !formValidated}"
                    v-html="btn">
            </button>

            <p class="seminar-registration__curator" v-html="curator"></p>
        </div>
        <FormResult
            :error-active="errorActive"
            :success-active="successActive"
            :success-text="successText"
            :error-text="errorText"
            @click="closeResult"/>
    </form>
</template>

<script setup>
import Checkbox from "../../vue-ui/Checkbox";
import FormInput from "../../vue-ui/FormInput";
import FormResult from "../../vue-ui/FormResult";
import {computed, reactive, ref, watch} from "vue";
import {useStore} from "vuex";
import Api from "@/api/api";

const name = "SeminarRegistration"
const store = useStore();
const api = new Api();

const data = store.state.currentData;

const placeholders = computed(() => data.seminar2.registration.placeholders);
const messages = computed(() => data.seminar2.registration.messages);
const checkboxText = computed(() => data.seminar2.registration.checkbox);
const successText = computed(() => data.seminar2.registration.result.success);
const errorText = computed(() => data.seminar2.registration.result.error);
const btn = computed(() => data.seminar2.registration.btn);
const curator = computed(() => data.seminar2.registration.curator);

const surnameSpec = reactive({
    model: '',
    name: 'surname',
    placeholder: placeholders.value['surname'],
    error: false,
    errorMsg: '',
    validated: false,
})
const nameSpec = reactive({
    model: '',
    name: 'name',
    placeholder: placeholders.value['name'],
    error: false,
    errorMsg: '',
    validated: false,
})

const emailSpec = reactive({
    model: '',
    name: 'e_mail',
    placeholder: placeholders.value['email'],
    error: false,
    errorMsg: '',
    validated: false,
    regexp: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
})
const phoneSpec = reactive({
    model: '',
    name: 'phone',
    placeholder: placeholders.value['phone'],
    error: false,
    errorMsg: '',
    validated: true,
    regexp: /^[+]?[0-9]{9,15}$/
})

const companySpec = reactive({
    model: '',
    name: 'company',
    placeholder: placeholders.value['company'],
    error: false,
    errorMsg: '',
    validated: false,
})
const positionSpec = reactive({
    model: '',
    name: 'position',
    placeholder: placeholders.value['position'],
    error: false,
    errorMsg: '',
    validated: false,
})

let agreeActive = ref(false);

let formValidated = ref(false);
let successActive = ref(false);
let errorActive = ref(false);

const agreeCheckboxClick = () => {
    agreeActive.value = !agreeActive.value;
}

const closeResult = () => {
    successActive.value = false;
    errorActive.value = false;
}

const validate = (obj) => {
    obj['error'] = false;
    obj['errorMsg'] = '';

    if (obj['model'] === '') {
        obj['error'] = true;
        obj['errorMsg'] = messages.value['empty'];
        obj['validated'] = false;
    } else {
        obj['validated'] = true;
    }
}

const validateWithRegexp = (obj, msg) => {
    obj['error'] = false;
    obj['errorMsg'] = '';
    obj['validated'] = false;

    if (obj['model'] === '') {
        obj['error'] = true;
        obj['errorMsg'] = messages.value['empty'];
        obj['validated'] = false;
    } else if (!(obj['regexp'].test(obj['model']))) {
        obj['error'] = true;
        obj['errorMsg'] = msg;
        obj['validated'] = false;
    } else {
        obj['validated'] = true;
    }
}

const validateSurname = () => {
    validate(surnameSpec)
}

const validateName = () => {
    validate(nameSpec)
}

const validatePhone = () => {
    validateWithRegexp(phoneSpec, messages.value['phone'])
}

const validateEmail = () => {
    validateWithRegexp(emailSpec, messages.value['email'])
}

const onSubmit = () => {
    let formData = new FormData();

    formData.append('surname', surnameSpec.model);
    formData.append('name', nameSpec.model);
    formData.append('email', emailSpec.model);
    formData.append('phone', phoneSpec.model);
    formData.append('company', companySpec.model);
    formData.append('position', positionSpec.model);

    api.seminarRegister(
        formData
    ).then((status) => {
        if (status === 200) {
            successActive.value = true;
        } else {
            errorActive.value = true;
        }
        console.log(status);
    });
}

watch([surnameSpec, nameSpec, emailSpec, phoneSpec, agreeActive], (newValue, oldValue) => {
    if (surnameSpec.validated && nameSpec.validated && emailSpec.validated && phoneSpec.validated && agreeActive.value) {
        formValidated.value = true;
    } else {
        formValidated.value = false;
    }
})
</script>

<style lang="scss">
.seminar-registration {
    position: relative;
    width: 100%;
    background-color: #3B4C7B;
    padding: 88px 10px;
    box-sizing: border-box;

    @media screen and (max-width: $mobile) {
        padding: 40px 10px;
    }

    &__main {
        width: 980px;
        margin: 0 auto;

        @media screen and (max-width: $mobile) {
            width: 100%;
        }

        & .form-input {
            width: 480px;
            margin-bottom: 36px;

            @media screen and (max-width: $mobile) {
                width: 100%;
                margin-bottom: 25px;
            }

            &__message {
                color: #A20B5D;
            }

            &._invalid {

                & .form-input__input {
                    border: 1px solid #A20B5D;
                }
            }
        }
    }

    &__wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 24px;
    }

    &__checkbox {
        margin-bottom: 40px;

        & .checkbox svg path {
            fill: #A20B5D;
            stroke: #A20B5D;
        }
    }

    &__submit {
        margin: 0 auto 40px;

        &._disabled {
            background: #C7D0E2;
            pointer-events: none;
        }
    }

    &__curator {
        width: 442px;
        margin: 0 auto;
        font-weight: 400;
        font-size: 20px;
        line-height: 140%;
        text-align: center;
        color: #FFFFFF;

        @media screen and (max-width: $mobile) {
            width: 100%;
            font-size: 14px;
        }
    }

    & .form-result {
        bottom: 250px;
    }
}
</style>