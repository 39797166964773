<template>
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14 2.30518H6C4.89543 2.30518 4 3.20061 4 4.30518V20.3052C4 21.4097 4.89543 22.3052 6 22.3052H18C19.1046 22.3052 20 21.4097 20 20.3052V8.30518L14 2.30518Z" stroke="#9A9A9A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14 2.30518V8.30518H20" stroke="#9A9A9A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16 13.3052H8" stroke="#9A9A9A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16 17.3052H8" stroke="#9A9A9A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10 9.30518H9H8" stroke="#9A9A9A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: "IconOpenPdf"
}
</script>

<style scoped>

</style>