<template>
    <div class="popup" :class="{'_active': popupOpened}">
        <div class="popup__wrapper" :class="{'_active': popupOpened}">
            <PartnersForm/>
        </div>
    </div>
</template>

<script setup>
import PartnersForm from "./blocks/Main/PartnersForm"
import {computed} from "vue";
import {useStore} from "vuex";

const name = "Popup";
const store = useStore();

const popupOpened = computed(() => store.state.popupOpened);

</script>

<style lang="scss">
.popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.6);
    transform: translateY(-100%);
    opacity: 0;
    transition: opacity .2s ease .3s,transform 0s ease .5s;
    z-index: 140;

    &._active {
        display: block;
        transform: translate(0);
        opacity: 1;
        transition: opacity .2s ease,transform 0s ease;
        backdrop-filter: blur(4px);
    }

    &__wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 21;
        opacity: 0;
        transform: translateY(-100%);
        transition: opacity 0s ease .3s,transform 0s ease .3s;
        pointer-events: all;
        overflow-y: auto;
        color: #202e35;

        &._active {
            transform: translate(0);
            opacity: 1;
            transition: opacity 0s ease,transform 0s ease;
        }

        @media screen and (max-width: $tablet-middle) {
            align-items: flex-start;
        }
    }
}
</style>