<template>
    <div class="contacts-content">
        <div class="contacts-content__item">
            <p class="contacts-content__head">{{fond.head}}</p>
            <p v-for="text in fond.text" class="contacts-content__text">{{text}}</p>
        </div>
        <div class="contacts-content__item">
            <p class="contacts-content__head">{{vavt.head}}</p>
            <a v-for="link in vavt.text" class="contacts-content__link" :href="link.link" target="_blank">
                {{link.content}}
            </a>
            <div class="contacts-content__logo">
                <IconLogoVavt/>
            </div>

        </div>
    </div>
</template>

<script setup>
import IconLogoVavt from "../../../assets/images/vue-icons/IconLogoVavt";
import {useStore} from "vuex";
import {computed} from "vue";

const name = "ContactsContent"

const store = useStore();

const data = store.state.currentData;
const fond = computed(() => data.contacts.fond);
const vavt = computed(() => data.contacts.vavt);

</script>

<style lang="scss">
.contacts-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 600px;
    padding-top: 138px;
    padding-bottom: 183px;

    @media screen and (max-width: $mobile) {
        padding-top: 60px;
        padding-bottom: 70px;
        height: 530px;
    }

    &__head {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: #2C2C2C;
        margin-bottom: 15px;

        @media screen and (max-width: $mobile) {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 12px;
        }
    }

    &__text {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #2C2C2C;
        margin-bottom: 15px;

        @media screen and (max-width: $mobile) {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 12px;
        }
    }

    &__link {
        display: block;
        text-decoration: none;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #2C2C2C;
        margin-bottom: 15px;

        @media screen and (max-width: $mobile) {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 12px;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &__logo {
        width: 300px;
        height: 80px;
        margin-top: 28px;

        @media screen and (max-width: $mobile) {
            width: 200px;
            height: 53.3px;

            & svg {
                width: 200px;
                height: 53.3px;
            }
        }
    }
}
</style>