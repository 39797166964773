import {currentHost} from './hosts';

class Api {
    constructor() {
        this.url = currentHost;
    }

    async postData(data = {}) {
        const response = await fetch(`${this.url}submit-form`, {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            redirect: 'manual', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
            body: data // body data type must match "Content-Type" header
        });
        return response.status; // parses JSON response into native JavaScript objects
    }

    async requestPartnership(data = {}) {
        const response = await fetch(`${this.url}request-part`, {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            redirect: 'manual', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
            body: data // body data type must match "Content-Type" header
        });
        return response.status; // parses JSON response into native JavaScript objects
    }

    async seminarRegister(data = {}) {
        const response = await fetch(`${this.url}seminar-registration`, {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            redirect: 'manual', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
            body: data // body data type must match "Content-Type" header
        });
        return response.status; // parses JSON response into native JavaScript objects
    }
}

export default Api;