<template>
    <div class="topics">
        <div v-if="filteredTopics1.length" class="topics__block">
            <img class="topics__img" :src="getImgUrl(topics1.img)" alt="">
            <div v-for="(topic, index) in filteredTopics1" :key="`topic-${index}`"
                 class="topics__item">

                <h4 class="topics__title">{{ topic.title }}</h4>
                <a v-for="(link, ind) in topic.links" :key="`topic-${index}-${ind}`" :href="link.link" target="_blank"
                   class="topics__link" v-html="link.name">
                </a>
            </div>
        </div>
        <div v-if="filteredTopics2.length" class="topics__block">
            <img class="topics__img" :src="getImgUrl(topics2.img)" alt="">
            <div v-for="(topic, index) in filteredTopics2" :key="`topic-${index}`"
                 class="topics__item">

                <h4 class="topics__title">{{ topic.title }}</h4>
                <a v-for="(link, ind) in topic.links" :key="`topic-${index}-${ind}`" :href="link.link" target="_blank"
                   class="topics__link" v-html="link.name">
                </a>
            </div>
        </div>
<!--        <div v-if="filteredTopics3.length" class="topics__block">-->
<!--            <img class="topics__img" :src="getImgUrl(topics3.img)" alt="">-->
<!--            <div v-for="(topic, index) in filteredTopics3" :key="`topic-${index}`"-->
<!--                 class="topics__item">-->

<!--                <h4 class="topics__title">{{ topic.title }}</h4>-->
<!--                <a v-for="(link, ind) in topic.links" :key="`topic-${index}-${ind}`" :href="link.link" target="_blank"-->
<!--                   class="topics__link">-->
<!--                    {{ link.name }}-->
<!--                </a>-->
<!--            </div>-->
<!--        </div>-->
        <div v-if="((filteredTopics1.length === 0) && (filteredTopics2.length === 0) && (filteredTopics3.length === 0))" class="topics__not-found" >По вашему запросу ничего не найдено</div>
    </div>
</template>

<script setup>
import {computed, ref} from "vue";
import {useStore} from "vuex";
import {currentHost} from "@/api/hosts";

const name = "Topics";

const store = useStore();
const data = store.state.currentData;
const topics1 = computed(() => data.support.topics.msp);
const topics2 = computed(() => data.support.topics.rec);
const topics3 = computed(() => data.support.topics.mpt);

let topics1Arr = ref([]);
let topics2Arr = ref([]);
let topics3Arr = ref([]);

const userInput = computed(() => store.state.userInput);

const downloadLink = `${currentHost}download/Вопросы.pdf`

let filteredTopics1 = computed(() => {
    if (userInput.value === '') {
        return topics1.value.list;
    } else {
        topics1Arr.value = [];
        topics1.value.list.forEach(item => {
            let filteredLinks = item.links.filter(d => d.name.toLowerCase().includes(userInput.value.toLowerCase()));

            if (filteredLinks.length > 0) {
                topics1Arr.value.push({
                    title: item.title,
                    links: filteredLinks
                })
            }
        })
        return topics1Arr.value
    }
});

let filteredTopics2 = computed(() => {
    if (userInput.value === '') {
        return topics2.value.list;
    } else {
        topics2Arr.value = [];
        topics2.value.list.forEach(item => {
            let filteredLinks = item.links.filter(d => d.name.toLowerCase().includes(userInput.value.toLowerCase()));

            if (filteredLinks.length > 0) {
                topics2Arr.value.push({
                    title: item.title,
                    links: filteredLinks
                })
            }
        })
        return topics2Arr.value
    }
});

// let filteredTopics3 = computed(() => {
//     if (userInput.value === '') {
//         return topics3.value.list;
//     } else {
//         topics3Arr.value = [];
//         topics3.value.list.forEach(item => {
//             let filteredLinks = item.links.filter(d => d.name.toLowerCase().includes(userInput.value.toLowerCase()));
//
//             if (filteredLinks.length > 0) {
//                 topics3Arr.value.push({
//                     title: item.title,
//                     links: filteredLinks
//                 })
//             }
//         })
//         return topics3Arr.value
//     }
// });

const getImgUrl = (name) => {
    return require(`../../../assets/images/org-logos/${name}`);
};

</script>

<style lang="scss">
.topics {
    width: 100%;
    min-height: 200px;
    padding-bottom: 100px;

    @media screen and (max-width: $mobile) {
        padding-bottom: 50px;
    }

    &__block {
        margin-bottom: 120px;

        @media screen and (max-width: $mobile) {
            margin-bottom: 60px;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__img {
        display: block;
        margin-bottom: 60px;
    }

    &__item {
        margin-bottom: 60px;

        @media screen and (max-width: $mobile) {
            margin-bottom: 40px;
        }
    }

    &__title {
        font-weight: 400;
        font-size: 28px;
        line-height: 30px;
        color: #052262;
        margin-bottom: 20px;
        margin-top: 0;

        @media screen and (max-width: $mobile) {
            font-size: 22px;
            line-height: 24px;
            margin-bottom: 15px;
        }
    }

    &__link {
        display: block;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: #242424;
        margin-bottom: 15px;
        text-underline-offset: 5px;
        padding: 0;
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        text-decoration: underline;

        @media screen and (max-width: $mobile) {
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 12px;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__not-found {
        font-weight: 400;
        font-size: 28px;
        line-height: 30px;
        color: #052262;
    }

    &__fedback {
        padding-top: 200px;
    }
}
</style>