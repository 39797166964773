<template>
    <div class="clear-btn"
         v-show=" userInput !== '' "
         @click="clear">
       <IconClear/>
    </div>
</template>

<script setup>
import IconClear from "../../assets/images/vue-icons/IconClear";

const name = "ClearButton";

const props = defineProps({
    userInput: {
        type: String,
        required: true
    },
});

const emits = defineEmits(['click']);
const clear = () => {
    emits('click');
}
</script>

<style lang="scss">
.clear-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 46px;
    height: 100%;
    background-color: transparent;
    cursor: pointer;
}
</style>