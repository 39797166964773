<template>
    <aside class="aside-menu" v-click-outside="closeMenu"
           :class="{'_opened': menuOpened}">
        <p class="aside-menu__title"
           :class="{'_opened': menuOpened, '_arm': language !== 'ru'}"
        >
            <span v-if="language === 'ru'">Фонд «ЦСР Республики Армения»</span>
            <span v-else>«Հայաստանի Հանրապետության ՌԶԿ» հիմնադրամ</span>
        </p>
        <button class="aside-menu__toggle"
                :class="{'_opened': menuOpened}"
                @click="toggleMenu">
            <IconOpenAside/>
        </button>
        <div class="aside-menu__navigation" @mouseenter="onHoverToggleMenu" @mouseleave="toggleMenu">
            <div class="aside-menu__item">
                <router-link :to="{name: 'Main'}" class="aside-menu__link" @click="closeMobileMenu">
                    <div class="aside-menu__icon">
                        <IconNavFond
                            :active="route.path === '/'"
                            :opened="menuOpened"
                        />
                    </div>
                    <span v-if="language === 'ru'">О фонде</span>
                    <span v-else>Հիմնադրամի մասին</span>
                </router-link>
            </div>
            <div class="aside-menu__item">
                <router-link :to="{name: 'Experts'}" class="aside-menu__link" @click="closeMobileMenu">
                    <IconNavExperts
                        :active="route.path === '/experts'"
                        :opened="menuOpened"
                    />
                    <span v-if="language === 'ru'">Эксперты фонда</span>
                    <span v-else>Փորձագետներ</span>
                </router-link>
            </div>
            <div class="aside-menu__item" @click="openAnalitics">
                <div class="aside-menu__link-arrow" :class="{'_opened': analiticsOpened}">
                    <IconArrow/>
                </div>
                <div class="aside-menu__link">
                    <IconNavAnalitics
                        :opened="menuOpened"
                    />
                    <div class="aside-menu__link-wrapper">
                        <span v-if="language === 'ru'">Аналитика</span>
                        <span v-else>Վերլուծություն</span>

                    </div>
                </div>
                <div class="aside-menu__dropdown" :class="{'_opened': analiticsOpened, '_hidden': !menuOpened}">
                    <a :href="createLink('macro')" v-on:click.stop class="aside-menu__link">
                        <span v-if="language === 'ru'">Ключевые макроэкономические показатели</span>
                        <span v-else>Մակրոտնտեսական հիմնական ցուցանիշներ</span>
                    </a>
                    <a :href="createLink('covid19')" v-on:click.stop class="aside-menu__link">
                        <span v-if="language === 'ru'">Эпидемиологическая динамика (COVID-19)</span>
                        <span v-else>Համաճարակաբանական դինամիկա (Covid-19)</span>
                    </a>
                    <a :href="createLink('investments')" v-on:click.stop class="aside-menu__link">
                        <span v-if="language === 'ru'">Инвестиции</span>
                        <span v-else>Ներդրումներ</span>
                    </a>
                    <a :href="createLink('labor')" v-on:click.stop class="aside-menu__link">
                        <span v-if="language === 'ru'">Труд и доходы населения</span>
                        <span v-else>Բնակչության աշխատուժը և եկամուտները</span>
                    </a>
                    <a :href="createLink('trade_in_goods')" v-on:click.stop class="aside-menu__link">
                        <span v-if="language === 'ru'">Торговля товарами</span>
                        <span v-else>Ապրանքների առևտուր</span>
                    </a>
                    <a :href="createLink('trade_in_services')" v-on:click.stop class="aside-menu__link">
                        <span v-if="language === 'ru'">Торговля услугами</span>
                        <span v-else>Ծառայություններ</span>
                    </a>


                </div>
            </div>
            <div class="aside-menu__item">
                <router-link :to="{name: 'Exhibitions'}" class="aside-menu__link" @click="closeMobileMenu">
                    <IconNavExhibition
                        :active="route.path === '/exhibitions'"
                        :opened="menuOpened"
                    />
                    <span v-if="language === 'ru'">Выставки</span>
                    <span v-else>Ցուցահանդեսներ</span>
                </router-link>
            </div>
            <div class="aside-menu__item">
                <router-link :to="{name: 'Seminar'}" class="aside-menu__link" @click="closeMobileMenu">
                    <IconNavNational
                        :active="route.path === '/seminar'"
                        :opened="menuOpened"
                    />
                    <span v-if="language === 'ru'">Национальный центр компетенций</span>
                    <span v-else>Իրավասությունների ազգային կենտրոն</span>
                </router-link>
            </div>
            <div class="aside-menu__item">
                <router-link class="aside-menu__link" :to="{name: 'Contacts'}" @click="closeMobileMenu">
                    <IconNavContacts
                        :active="route.path === '/contacts'"
                        :opened="menuOpened"
                    />
                    <span v-if="language === 'ru'">Контакты</span>
                    <span v-else>Հետադարձ կապ</span>
                </router-link>
            </div>
            <div class="aside-menu__item" @click="openSupport">
                <div class="aside-menu__link-arrow"
                     :class="{'_opened': supportOpened, '_active': route.path === '/support' || route.path === '/faq'}">
                    <IconArrow/>
                </div>
                <div class="aside-menu__link" :class="{'_active': route.path === '/support' || route.path === '/faq'}">
                    <IconNavSupport
                        :opened="menuOpened"
                        :active="route.path === '/support' || route.path === '/faq'"
                    />
                    <div class="aside-menu__link-wrapper">
                        <span v-if="language === 'ru'">Предпринимателям</span>
                        <span v-else>Ձեռնարկատերերին</span>

                    </div>
                </div>
                <div class="aside-menu__dropdown" :class="{'_opened': supportOpened, '_hidden': !menuOpened}">
                    <router-link v-on:click.stop class="aside-menu__link" :to="{name: 'Support'}"
                                 :class="{'_active': route.path === '/support' || route.path === '/faq'}" @click="closeMobileMenu">
                        <span v-if="language === 'ru'">Ресурсы поддержки предпринимателей</span>
                        <span v-else>Ձեռնարկատերերի աջակցության ռեսուրսներ</span>
                    </router-link>
                    <router-link v-on:click.stop :to="{name: 'FAQ'}" class="aside-menu__link"
                                 :class="{'_active': route.path === '/support' || route.path === '/faq'}" @click="closeMobileMenu">
                        <span v-if="language === 'ru'">Вопрос-ответ</span>
                        <span v-else>Հարց-պատասխան</span>
                    </router-link>
                </div>
            </div>
        </div>
    </aside>
</template>

<script setup>
import IconOpenAside from "../assets/images/vue-icons/navigation/IconOpenAside";
import IconArrow from "@/assets/images/vue-icons/IconArrow";
import IconNavFond from "@/assets/images/vue-icons/navigation/IconNavFond";
import IconNavExperts from "@/assets/images/vue-icons/navigation/IconNavExperts";
import IconNavAnalitics from "@/assets/images/vue-icons/navigation/IconNavAnalitics";
import IconNavExhibition from "@/assets/images/vue-icons/navigation/IconNavExhibition";
import IconNavSupport from "@/assets/images/vue-icons/navigation/IconNavSupport";
import IconNavNational from "@/assets/images/vue-icons/navigation/IconNavNational";
import IconNavContacts from "@/assets/images/vue-icons/navigation/IconNavContacts";
import {computed, reactive, ref} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";

const name = "AsideMenu";

const store = useStore();
const route = useRoute();


const language = computed(() => store.state.currentLanguage);
const menuOpened = computed(() => store.state.asideMenuOpened)

let supportOpened = ref(false);
let analiticsOpened = ref(false);


const toggleMenu = () => {
    store.commit('toggleAside');
}

const closeMenu = () => {
    store.commit('closeAside');
}

const closeMobileMenu = () => {
    if (window.innerWidth <= 500) {
        store.commit('closeAside');
    }
}


const onHoverToggleMenu = () => {
    if (!menuOpened.value) {
        toggleMenu()
    }
}

const openSupport = () => {
    supportOpened.value = !supportOpened.value;
}

const openAnalitics = () => {
    analiticsOpened.value = !analiticsOpened.value;
}

const createLink = (str) => {
    if (process.env.VUE_APP_DEPLOYMENT_ENV === 'sandbox') {
        return 'http://10.8.0.8:8528/economics/'
    } else {
        return `https://fb.vavt.ru/economics/analytics/${str}`
    }
}

</script>

<style lang="scss">
.aside-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    width: 80px;
    padding-top: 20px;
    overflow: hidden;
    background-color: #3B4C7B;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.08), 0 8px 24px rgba(0, 0, 0, 0.08);
    transition: width .3s ease-in-out;


    @media screen and (max-width: $mobile) {
        transform: translateX(-80px);
        background-color: #FFFFFF;
    }

    &._opened {
        width: 300px;
        background-color: #FFFFFF;
        overflow-y: scroll;

        @media screen and (max-width: $mobile) {
            width: 300px;
            transform: translateX(0);
        }

        & .aside-menu__link {

            & span {
                overflow: visible;
                white-space: normal;
                transform: translateX(0);
                opacity: 1;
            }

            &:hover {
                background-color: #D9D9D9;
            }

            &.router-link-active {
                background-color: #3E91D8;

                & span {
                    color: #FFFFFF;
                    opacity: 1;
                }
            }

            &._active {
                background-color: lighten(#3E91D8, 5%);

                & span {
                    color: #FFFFFF;
                    opacity: 1;
                }

                &.router-link-active {
                    background-color: #3E91D8;

                    & span {
                        color: #FFFFFF;
                        opacity: 1;
                    }
                }
            }
        }

        & .aside-menu__dropdown {
            & .aside-menu__link._active {

                & span:before {
                    background: #FFFFFF;
                }
            }
        }

        &::-webkit-scrollbar {
            width: 5px;
            //height: 6px;
        }

        &::-webkit-scrollbar-track {
            background-color: #E9ECEF;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #0083D0;
            border-radius: 10px;
        }

    }

    &__title {
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        min-height: 60px;
        color: #FFFFFF;
        margin: 0;
        align-self: center;
        overflow: hidden;
        transform: translateX(16px);
        opacity: 0;
        transition: all .3s ease-in-out;
        pointer-events: none;

        & span {
            display: block;
            width: 300px;
            padding-right: 20px;
            padding-left: 20px;
            box-sizing: border-box;
        }

        &._opened {
            transform: translateX(0);
            opacity: 1;
            pointer-events: all;
            color: #2C2C2C;
        }
    }

    &__toggle {
        position: absolute;
        right: 18px;
        top: 8px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        padding: 0;
        border: none;
        color: #ccc;
        background-color: transparent;
        cursor: pointer;

        &._opened {
            transform: rotateY(180deg);

            & svg path {
                fill: #2C2C2C;
            }
        }
    }

    &__navigation {
        width: 100%;
        margin-top: 40px;
    }

    &__item {
        position: relative;
        width: 300px;
        //overflow: hidden;
    }

    &__link {
        position: relative;
        display: flex;
        align-items: center;
        min-height: 62px;
        height: 62px;
        padding: 15px 28px;
        text-decoration: none;
        box-sizing: border-box;
        transition: background-color .3s ease-in-out;
        cursor: pointer;


        & svg {
            width: 24px;
            height: 24px;
            margin-right: 15px;

            & path {
                //fill: #9FD1FC;
                transition: all .3s ease-in-out;
            }
        }

        & span {
            flex: 1 1;
            overflow: hidden;
            font-size: 16px;
            white-space: nowrap;
            transform: translateX(16px);
            opacity: 0;
            transition: opacity .3s ease-in-out, transform .3s ease-in-out;
            //font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            color: #2C2C2C;
        }

        &.router-link-active {
            background-color: darken(#3B4C7B, 10%);
        }

        &._active {
            background-color: darken(#3B4C7B, 10%);
        }

        &-wrapper {
            position: relative;
        }

        &-arrow {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 13px;
            height: 8px;
            top: 27px;
            right: 18px;
            z-index: 3;

            & svg {
                width: 100%;
                height: 100%;
                margin: 0;
                transition: all .3s ease;

                & path {
                    fill: #2C2C2C;
                    opacity: 0.7;
                }
            }

            &._opened {

                & svg {
                    transform: rotate(180deg);
                }
            }

            &._active svg path {
                fill: #FFFFFF;
            }
        }
    }

    &__lang {
        width: 30px;
        height: 20px;
        margin-left: 25px;
        cursor: pointer;
        margin-top: 25px;
    }

    &__dropdown {
        max-height: 0;
        overflow: hidden;
        transition: max-height .3s ease-in-out;

        & span {
            position: relative;
            font-weight: 400;
            padding-left: 35px;


            &:before {
                position: absolute;
                content: '';
                top: 7px;
                left: 23px;
                width: 6px;
                height: 6px;
                background: #3E91D8;
                border-radius: 50%;
                opacity: 1;
            }
        }

        &._opened {
            max-height: 1400px;

            //@media screen and (max-width: $mobile) {
            //    max-height: 3000px;
            //}
        }

        &._hidden {
            display: none;
        }
    }
}
</style>
