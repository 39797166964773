<template>
    <section class="seminar-about">
        <h1 class="seminar-about__title" v-html="title"></h1>
        <p class="seminar-about__subtitle" v-html="subtitle"></p>
        <p class="seminar-about__date">{{ date }}</p>
        <p class="seminar-about__desc" v-html="desc"></p>
        <p v-for="item in list" class="seminar-about__text" v-html="item"></p>
        <a href="#reg" class="seminar-about__btn btn btn-blue">{{ btn }}</a>
    </section>
</template>

<script setup>
import {useStore} from "vuex";
import {computed} from "vue";

const name = "SeminarAbout";
const store = useStore();

const data = store.state.currentData;
const title = computed(() => data.seminar2.about.title);
const subtitle = computed(() => data.seminar2.about.subtitle);
const date = computed(() => data.seminar2.about.date);
const desc = computed(() => data.seminar2.about.desc);
const list = computed(() => data.seminar2.about.list);
const btn = computed(() => data.seminar2.about.btn);

</script>

<style lang="scss">
.seminar-about {
    padding-top: 73px;

    @media screen and (max-width: $mobile) {
        padding-top: 40px;
    }

    &__title {
        font-weight: 700;
        font-size: 30px;
        line-height: 150%;
        color: #FC7E2F;
        margin-bottom: 20px;
        margin-top: 0;

        @media screen and (max-width: $mobile) {
            font-size: 22px;
        }
    }

    &__subtitle {
        font-weight: 700;
        font-size: 22px;
        line-height: 140%;
        color: #052262;
        margin-bottom: 20px;

        @media screen and (max-width: $mobile) {
            font-size: 16px;
        }
    }

    &__date {
        font-weight: 600;
        font-size: 22px;
        line-height: 140%;
        margin-bottom: 50px;
        color: #65ADEB;

        @media screen and (max-width: $mobile) {
            font-size: 16px;
            margin-bottom: 35px;
        }
    }

    &__desc {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #2C2C2C;
        margin-bottom: 40px;
        text-align: justify;

        @media screen and (max-width: $mobile) {
            font-size: 14px;
            margin-bottom: 30px;
            line-height: 22px;
        }
    }

    &__text {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #2C2C2C;
        margin-bottom: 15px;
        text-align: justify;

        &:last-of-type {
            margin-bottom: 50px;
        }

        @media screen and (max-width: $mobile) {
            font-size: 14px;
            margin-bottom: 10px;
            line-height: 22px;

            &:last-of-type {
                margin-bottom: 35px;
            }
        }
    }

    &__btn {
        margin: 0 auto;
    }
}
</style>