<template>
    <div class="app-body">
        <AsideMenu/>
        <Header :key="language"/>
        <slot/>
        <Footer :key="language"/>
        <div v-if="menuOpened" class="body-overlay"></div>
    </div>
</template>

<script setup>
import Header from "../components/Header";
import AsideMenu from "../components/AsideMenu"
import Footer from "../components/Footer";
import {computed} from "vue";
import {useStore} from "vuex";

const store = useStore();

const language = computed(() => store.state.currentLanguage);
const menuOpened = computed(() => store.state.asideMenuOpened)

</script>

<style scoped>

</style>
