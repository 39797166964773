<template>
    <svg viewBox="0 0 24 24"><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" fill="#2C2C2C"></path></svg>
</template>

<script>
export default {
    name: "IconBurger"
}
</script>

<style scoped>

</style>