<template>
    <ul class="exhibitions-list">
        <li v-for="item in list" class="exhibitions-list__item">
            <a :href="item.link" class="exhibitions-list__link" target="_blank">
                <p class="exhibitions-list__date" :class="{'_arm': language === 'arm'}" v-html="item.date"></p>
                <p class="exhibitions-list__name" :class="{'_arm': language === 'arm'}" v-html="item.name"></p>
            </a>
        </li>
    </ul>
</template>

<script setup>

import {useStore} from "vuex";
import {computed} from "vue";

const name = "ExhibitionsList"

const store = useStore();
const language = computed(() => store.state.currentLanguage);
const data = store.state.currentData;
const list = computed(() => data.exhibitions.list);

</script>

<style lang="scss">
.exhibitions-list {
    padding: 0 100px;
    margin: 0;
    list-style: none;

    @media screen and (max-width: $mobile) {
        padding: 0;
    }

    &__item {
        margin-bottom: 46px;

        @media screen and (max-width: $mobile) {
            margin-bottom: 27px;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__link {
        display: flex;
        width: fit-content;
        text-decoration: none;

        @media screen and (max-width: $mobile) {
            flex-direction: column-reverse;
            width: 100%;
            background-color: #3B4C7B;
            border-radius: 5px;
            padding: 20px 8px;
            box-sizing: border-box;
        }

        @media screen and (min-width: $tablet-middle) {
            &:hover {
                & .exhibitions-list__name {
                    color: #FC7E2F;
                }

                & .exhibitions-list__date {
                    background-color: #FC7E2F;
                }
            }
        }
    }

    &__date {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 277px;
        height: 40px;
        background-color: #3B4C7B;
        border-radius: 5px;
        margin-right: 20px;
        color: #FFFFFF;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        transition: all .3s ease;

        @media screen and (max-width: $mobile) {
            margin-right: 0;
            width: 100%;
            font-size: 16px;
            line-height: 30px;
            background-color: transparent;
        }

        &._arm {
            font-size: 17px;
            min-width: 340px;

            @media screen and (max-width: $mobile) {
                font-size: 14px;
                width: 100%;
                min-width: auto;
            }
        }
    }

    &__name {
        font-weight: 400;
        font-size: 30px;
        line-height: 40px;
        color: #2C2C2C;
        transition: color .3s ease;
        text-transform: uppercase;

        @media screen and (max-width: $mobile) {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 14px;
        }

        &._arm {
            font-size: 24px;

            @media screen and (max-width: $mobile) {
                font-size: 18px;
            }
        }
    }
}

</style>