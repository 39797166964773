<template>
    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1.33862" width="20" height="20" rx="4" stroke="white" stroke-width="2"/>
    </svg>
</template>

<script>
export default {
    name: "IconUncheked"
}
</script>

<style scoped>

</style>