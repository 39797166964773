<template>
    <form class="partners-form"
          :class="{'_active': popupOpened}"
          enctype="multipart/form-data"
          autocomplete="off"
          method="POST"
          novalidate
          v-on:submit.prevent="onSubmit">
        <div class="partners-form__main" :class="{'_hidden': errorActive || successActive}">
            <FormInput
                :input-spec="fioSpec"
                @input="validateFio"/>
            <FormInput
                :input-spec="positionSpec"
            />
            <FormInput
                :input-spec="companySpec"
                @input="validateCompany"/>


            <FormInput
                :input-spec="countrySpec"
                @input="validateCountry"
            />

            <FormInput
                :input-spec="partnershipSpec"
                @input="validatePartnership"/>

            <FormInput
                :input-spec="phoneSpec"
                @input="validatePhone"
            />
            <FormInput
                :input-spec="emailSpec"
                @input="validateEmail"
            />
            <div class="partners-form__checkbox">
                <Checkbox
                    :text="checkboxText"
                    :active="agreeActive"
                    @click="agreeCheckboxClick"/>
            </div>
            <button class="partners-form__submit" type="submit" :class="{'_disabled': !formValidated}">
                <span v-if="language === 'ru'">Отправить</span>
                <span v-else>Send request</span>
            </button>
            <div class="partners-form__cross"
                 @click="closePopup">
                <IconCross/>
            </div>
        </div>

        <FormResult
            :error-active="errorActive"
            :success-active="successActive"
            :success-text="successText"
            :error-text="errorText"
            @click="closePopup"/>
    </form>
</template>

<script setup>
import IconCross from "../../../assets/images/vue-icons/IconCross";
import Checkbox from "../../vue-ui/Checkbox";
import FormInput from "../../vue-ui/FormInput";
import FormResult from "../../vue-ui/FormResult";
import Api from "../../../api/api";
import {useStore} from "vuex";
import {computed, reactive, ref, watch} from "vue";
import {blockBody} from "@/scripts/utils";

const name = "PartnersForm";

const api = new Api();
const store = useStore();

const data = store.state.currentData;


const language = computed(() => store.state.currentLanguage);
const popupOpened = computed(() => store.state.popupOpened);

const placeholders = computed(() => data.main.partners.form.placeholders);

const messages = computed(() => data.main.partners.form.messages);
const checkboxText = computed(() => data.main.partners.form.checkbox);
const successText = computed(() => data.main.partners.form.result.success);
const errorText = computed(() => data.main.partners.form.result.error);

let agreeActive = ref(false);

let formValidated = ref(false);
let successActive = ref(false);
let errorActive = ref(false);

const fioSpec = reactive({
    model: '',
    name: 'fio',
    placeholder: placeholders.value['fio'],
    error: false,
    errorMsg: '',
    validated: false,
})
const positionSpec = reactive({
    model: '',
    name: 'position',
    placeholder: placeholders.value['position'],
    error: false,
    errorMsg: '',
    validated: false,
})
const companySpec = reactive({
    model: '',
    name: 'company',
    placeholder: placeholders.value['company'],
    error: false,
    errorMsg: '',
    validated: false,
})
const countrySpec = reactive({
    model: '',
    name: 'country',
    placeholder: placeholders.value['country'],
    error: false,
    errorMsg: '',
    validated: false,
})
const partnershipSpec = reactive({
    model: '',
    name: 'text',
    placeholder: placeholders.value['partnership'],
    error: false,
    errorMsg: '',
    validated: false,
})
const phoneSpec = reactive({
    model: '',
    name: 'phone',
    placeholder: placeholders.value['phone'],
    error: false,
    errorMsg: '',
    validated: true,
    regexp: /^[+]?[0-9]{9,15}$/
})
const emailSpec = reactive({
    model: '',
    name: 'e_mail',
    placeholder: placeholders.value['email'],
    error: false,
    errorMsg: '',
    validated: false,
    regexp: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
})


const agreeCheckboxClick = () => {
    agreeActive.value = !agreeActive.value;
}

const validate = (obj) => {
    obj['error'] = false;
    obj['errorMsg'] = '';

    if (obj['model'] === '') {
        obj['error'] = true;
        obj['errorMsg'] = messages.value['empty'];
        obj['validated'] = false;
    } else {
        obj['validated'] = true;
    }
}

const validateWithRegexp = (obj, msg) => {
    obj['error'] = false;
    obj['errorMsg'] = '';
    obj['validated'] = false;

    if (obj['model'] === '') {
        obj['error'] = true;
        obj['errorMsg'] = messages.value['empty'];
        obj['validated'] = false;
    } else if (!(obj['regexp'].test(obj['model']))) {
        obj['error'] = true;
        obj['errorMsg'] = msg;
        obj['validated'] = false;
    } else {
        obj['validated'] = true;
    }
}

const validateFio = () => {
    validate(fioSpec)
}

const validateCompany = () => {
    validate(companySpec)
}

const validateCountry = () => {
    validate(countrySpec)
}

const validatePhone = () => {
    validateWithRegexp(phoneSpec, messages.value['phone'])
}

const validateEmail = () => {
    validateWithRegexp(emailSpec, messages.value['email'])
}

const validatePartnership = () => {
    validate(partnershipSpec)
}

const closePopup = () => {
    blockBody();
    store.commit('togglePopup');
    successActive.value = false;
    errorActive.value = false;
}

const onSubmit = () => {
    let formData = new FormData();

    formData.append('fio', fioSpec.model);
    formData.append('position', positionSpec.model);
    formData.append('company', companySpec.model);
    formData.append('country', countrySpec.model);
    formData.append('partnership', partnershipSpec.model);
    formData.append('phone', phoneSpec.model);
    formData.append('email', emailSpec.model);


    // for (let i = 0; i < upload.value.files.length; i++) {
    //     formData.append('files', upload.value.files[i])
    // }

    api.requestPartnership(
        formData
    ).then((status) => {
        if (status === 200) {
            successActive.value = true;
        } else {
            errorActive.value = true;
        }
        console.log(status);
    });
}


watch([companySpec, fioSpec, countrySpec, phoneSpec, emailSpec, agreeActive, partnershipSpec], (newValue, oldValue) => {
    if (companySpec.validated && fioSpec.validated && countrySpec.validated && phoneSpec.validated && emailSpec.validated && agreeActive.value && partnershipSpec.validated) {
        formValidated.value = true;
    } else {
        formValidated.value = false;
    }
})
</script>

<style lang="scss">
.partners-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    opacity: 0;
    transform: scale(.7);
    animation: formDisappearing .2s ease-in;
    animation-fill-mode: forwards;

    @media screen and (max-width: $mobile) {
        justify-content: flex-start;
        height: 100%;

    }

    &._active {
        animation: formAppearing .5s ease-in;
        animation-delay: .2s;
        animation-fill-mode: forwards;
    }

    &__main {
        width: 620px;
        padding: 60px 50px 40px;
        background: #3B4C7B;
        border-radius: 5px;
        box-sizing: border-box;

        @media screen and (max-width: $mobile) {
            width: 100%;
            height: 100%;
            overflow: scroll;
        }

        &._hidden {
            opacity: 0;
            pointer-events: none;
        }

        & .form-input {
            width: 100%;
            margin-bottom: 30px;
        }

        & .form-input__input {
            height: 45px;
        }
    }

    &__checkbox {
        padding-top: 10px;
        margin-bottom: 35px;

        & .checkbox {
            align-items: flex-start;

        }

        & .checkbox__text {
            color: #FFFFFF;
            font-size: 14px;
            line-height: 22px;
        }
    }

    &__submit {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 240px;
        height: 60px;
        border-radius: 8px;
        background-color: #052262;
        margin: 0 auto;
        padding: 0;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #FFFFFF;
        text-align: center;
        border: none;
        outline: none;
        cursor: pointer;
        transition: all .3s ease;

        &._disabled {
            background: #C7D0E2;
            pointer-events: none;
        }

        //&:hover {
        //    background-color: lighten(#052262, 5%);
        //}
    }

    &__cross {
        position: absolute;
        width: 13px;
        height: 13px;
        top: 19px;
        right: 23px;
        cursor: pointer;
        z-index: 5;

        & svg path {
            transition: all .3s ease;
            fill: #C7D0E2;
        }

        &:hover {
            & svg path {
                fill: darken(#C7D0E2, 10%);
            }
        }
    }
}

@keyframes formAppearing {
    0% {
        opacity: 0;
        transform: scale(.7)
    }
    60% {
        opacity: 1;
        transform: scale(.95)
    }
    75% {
        opacity: 1;
        transform: scale(.92)
    }
    90% {
        opacity: 1;
        transform: scale(1.03)
    }
    to {
        opacity: 1;
        transform: scale(1)
    }
}

@keyframes formDisappearing {
    0% {
        transform: scale(1)
    }
    to {
        opacity: 0;
        transform: scale(1.2)
    }
}
</style>