<template>
    <div :class="{'_active' : active}"
         class="checkbox"
         @click="onClick">
        <div class="checkbox__wrapper">
            <IconChecked v-if="active"/>
            <IconUnchecked v-else/>
        </div>
        <span class="checkbox__text" v-html="text"></span>
    </div>
</template>

<script setup>
import IconChecked from "../../assets/images/vue-icons/IconChecked";
import IconUnchecked from "../../assets/images/vue-icons/IconUnchecked";

const name = "Checkbox";

const props = defineProps({
    text: {
        type: String,
        required: true
    },

    active: {
        type: Boolean,
    },
})

const emits = defineEmits(['click']);

const onClick = (val) => {
    emits('click', val)
}

</script>

<style lang="scss">
.checkbox {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: color .3s ease;

    &__wrapper {
        width: 20px;
        height: 20px;

        & svg path {
            fill: #FC7E2F;
            stroke: #FC7E2F;
        }
    }

    &__text {
        font-weight: 600;
        font-size: 12px;
        line-height: 22px;
        color: #FFFFFF;
        margin-left: 13px;
    }
}
</style>
