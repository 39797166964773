<template>
    <div class="contacts">
        <div class="container">
            <ContactsHead :key="language"/>
            <ContactsContent :key="language"/>
        </div>
    </div>
</template>

<script setup>
import ContactsHead from "../components/blocks/Contacts/ContactsHead"
import ContactsContent from "../components/blocks/Contacts/ContactsContent"
import {useStore} from "vuex";
import {computed} from "vue";

const name = "Contacts";

const store = useStore();
const language = computed(() => store.state.currentLanguage);

</script>

<style lang="scss">

</style>