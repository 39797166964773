<template>
    <PageHead
        :title="title"/>
</template>

<script setup>
import PageHead from "@/components/PageHead";
import {useStore} from "vuex";
import {computed} from "vue";

const name = "SupportHead"
const store = useStore();

const data = store.state.currentData;
const title = computed(() => data.support.title);

</script>

<style lang="scss">
</style>

