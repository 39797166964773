<template>
    <div class="projects">
        <h2 class="projects__title h2" v-html="title"></h2>
        <ul class="projects__list">
            <li v-for="item in list" class="projects__item">
                <img :src="getImgUrl(item.img)" class="projects__img"/>
                <p class="projects__name" v-html="item.name"></p>
                <p class="projects__description" v-html="item.description"></p>
            </li>

        </ul>
    </div>
</template>

<script setup>
import {useStore} from "vuex";
import {computed} from "vue";

const name = "Projects";
const store = useStore();

const data = store.state.currentData;
const title = computed(() => data.main.projects.title);
const list = computed(() => data.main.projects.list);

const getImgUrl = (pic) => {
    return require('../../../assets/images/' + pic);
};
</script>

<style lang="scss">
.projects {
    padding-top: 100px;
    padding-bottom: 150px;

    @media screen and (max-width: $mobile) {
        padding-top: 60px;
        padding-bottom: 70px;
    }

    &__list {
        width: 980px;
        list-style: none;
        padding: 0;
        margin: 0 auto;

        @media screen and (max-width: $mobile) {
            width: 100%;
        }
    }

    &__item {
        margin-bottom: 80px;

        @media screen and (max-width: $mobile) {
            margin-bottom: 40px;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__img {
        display: block;
        width: 100%;
        height: 360px;
        margin-bottom: 30px;

        @media screen and (max-width: $mobile) {
            height: 130px;
            margin-bottom: 20px;
        }
    }

    &__name {
        font-weight: 400;
        font-size: 28px;
        line-height: 46px;
        color: #052262;
        margin-bottom: 30px;

        @media screen and (max-width: $mobile) {
            font-size: 18px;
            line-height: 28px;
        }
    }

    &__description {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #2C2C2C;
        text-align: justify;

        @media screen and (max-width: $mobile) {
            font-size: 14px;
            line-height: 26px;
        }

        & span {
            color: rgba(252, 126, 47, 1)
        }
    }
}
</style>