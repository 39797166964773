<template>
    <div class="faq-list">
        <FAQItem v-for="item in list"
                 :item-spec="item"/>
    </div>
</template>

<script setup>
import FAQItem from "./FAQItem"
import {computed} from "vue";
import {useStore} from "vuex";

const name = "FAQList";

const store = useStore();
const data = store.state.currentData;
const list = computed(() => data.faq.list);
</script>

<style lang="scss">
.faq-list {
    padding-top: 73px;
    padding-bottom: 100px;

    @media screen and (max-width: $mobile) {
        padding-top: 50px;
        padding-bottom: 70px;
    }
}
</style>