<template>
    <List
        :title="title"
        :list="list"/>
</template>

<script setup>
import List from "../../List"
import {useStore} from "vuex";
import {computed} from "vue";

const name = "Goals"
const store = useStore();

const data = store.state.currentData;
const title = computed(() => data.main.goals.title);
const list = computed(() => data.main.goals.list);
</script>

<style lang="scss">

</style>