<template>
    <section class="seminar-objective">
        <h2 class="seminar-objective__title" v-html="title"></h2>
        <ul class="seminar-objective__list">
            <li v-for="(item, index) in list" :key="`item-${index}`" class="seminar-objective__item">
                <p class="seminar-objective__name" v-html="item"></p>
            </li>
        </ul>
    </section>
</template>

<script setup>
import {useStore} from "vuex";
import {computed} from "vue";

const name = "SeminarObjective";

const store = useStore();

const data = store.state.currentData;
const title = computed(() => data.seminar2.objectives.title);
const list = computed(() => data.seminar2.objectives.list);


</script>

<style lang="scss">
.seminar-objective {
    padding-top: 120px;

    @media screen and (max-width: $mobile) {
        padding-top: 60px;
    }

    &__title {
        font-weight: 600;
        font-size: 28px;
        line-height: 30px;
        color: #3E91D8;
        margin-top: 0;
        margin-bottom: 60px;

        @media screen and (max-width: $mobile) {
            font-size: 22px;
            margin-bottom: 30px;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__item {
        position: relative;
        width: 580px;
        padding-left: 34px;
        box-sizing: border-box;
        margin-bottom: 50px;

        @media screen and (max-width: $mobile) {
            margin-bottom: 20px;
            padding-left: 20px;
        }

        &:before {
            position: absolute;
            content: '';
            width: 16px;
            height: 16px;
            top: 6px;
            left: 0;
            background-color: #65ADEB;

            @media screen and (max-width: $mobile) {
                width: 8px;
                height: 8px;
            }
        }
    }

    &__name {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #052262;

        @media screen and (max-width: $mobile) {
            font-size: 14px;
            line-height: 22px;
            //text-align: center;

        }
    }
}
</style>