<template>
    <form class="feedback-form"
          enctype="multipart/form-data"
          autocomplete="off"
          method="POST"
          novalidate
          v-on:submit.prevent="onSubmit">
        <div class="feedback-form__main" :class="{'_hidden': errorActive || successActive}">
            <h5 class="feedback-form__title" v-html="title"></h5>

            <div class="feedback-form__category">
                <p class="feedback-form__category-desc">{{ categories.desc }}</p>
                            <FormRadio
                                :radio-spec="categoriesRadio"
                                @change="changeCategory"
                            />
            </div>
            <div class="feedback-form__row">
                <FormInput
                    :input-spec="surnameSpec"
                    @input="validateSurname"/>
                <FormInput
                    :input-spec="nameSpec"
                    @input="validateName"/>
                <FormInput
                    :input-spec="patronymSpec"/>
            </div>



            <div class="feedback-form__row">
                <FormInput
                    :input-spec="emailSpec"
                    @input="validateEmail"
                />
                <FormInput
                    :input-spec="phoneSpec"
                    @input="validatePhone"
                />
                <FormInput
                    :input-spec="companySpec"
                    @input="validateCompany"/>
            </div>


            <FormSelect
                :select-spec="typeSpecs"
                @change="changeType"/>


            <FormTextarea
                :input-spec="textSpec"
                @input="validateText"
            />

                        <div class="feedback-form__upload">
                            <div class="feedback-form__upload-file-list">
                                <div v-for="item in uploadedFiles" class="feedback-form__upload-file-item">
                                    <span>{{ item.name }}</span>
                                    <div class="feedback-form__upload-file-item-cross" @click="deleteFile(item.name)">
                                        <IconCross/>
                                    </div>
                                </div>
                            </div>
                            <div class="feedback-form__upload-wrapper">
                                <label for="doc_upload" class="feedback-form__upload-label">
                                    <IconUpload/>
                                    <span v-if="language === 'ru'">Прикрепить файл</span>
                                    <span v-else>Կցել ֆայլ</span>
                                </label>
                                <input id="doc_upload" ref="upload" type="file" name="doc_upload" multiple accept=".pdf"
                                       class="feedback-form__upload-input"
                                       @change="uploadFiles">
                            </div>
                        </div>
            <div class="feedback-form__checkbox">
                <Checkbox
                    :text="checkboxText"
                    :active="agreeActive"
                    @click="agreeCheckboxClick"/>
            </div>
            <button class="feedback-form__submit" type="submit" :class="{'_disabled': !formValidated}">
                <span v-if="language === 'ru'">Отправить</span>
                <span v-else>Ուղարկել</span>
            </button>
        </div>

        <FormResult
            :error-active="errorActive"
            :success-active="successActive"
            :success-text="successText"
            :error-text="errorText"
            @click="closeResult"/>
    </form>
</template>

<script setup>
import Checkbox from "./vue-ui/Checkbox";
import FormRadio from "./vue-ui/FormRadio";
import FormInput from "./vue-ui/FormInput";
import FormSelect from "./vue-ui/FormSelect";
import FormTextarea from "./vue-ui/FormTextarea";
import FormResult from "./vue-ui/FormResult";
import IconCross from "@/assets/images/vue-icons/IconCross";
import IconUpload from "../assets/images/vue-icons/IconUpload";

import Api from "../api/api.js"
import {computed, ref, watch, reactive} from "vue";
import {useStore} from "vuex";
import {blockBody} from "@/scripts/utils";

const name = "FeedbackForm";
const api = new Api();
const store = useStore();

const data = store.state.currentData;


const language = computed(() => store.state.currentLanguage);
const placeholders = computed(() => data.support.registration.placeholdersMain);
const title = computed(() => data.support.registration.title);
const categories = computed(() => data.support.registration.categories);
const types = computed(() => data.support.registration.types);

const messages = computed(() => data.support.registration.messages);
const checkboxText = computed(() => data.support.registration.checkbox);
const successText = computed(() => data.support.registration.result.success);
const errorText = computed(() => data.support.registration.result.error);

let agreeActive = ref(false);

let formValidated = ref(false);
let successActive = ref(false);
let errorActive = ref(false);

let category = ref('Физическое лицо');
let type = ref('')

const categoriesRadio = [
    {
        name: 'category',
        id: categories.value.phPerson,
        label: categories.value.phPerson,
        checked: true
    },

    {
        name: 'category',
        id: categories.value.jPerson,
        label: categories.value.jPerson,
        checked: false
    },
];

const typeSpecs = {
    id: 'types',
    specs: {
        value: type.value,
        placeholder: types.value.placeholder,
        options: types.value.options,
        closeOnSelect: true,
        canDeselect: false,
        canClear: false
    },
};

const surnameSpec = reactive({
    model: '',
    placeholder: placeholders.value['surname'],
    error: false,
    errorMsg: '',
    validated: false,
})

const nameSpec = reactive({
    model: '',
    placeholder: placeholders.value['name'],
    error: false,
    errorMsg: '',
    validated: false,
})

const patronymSpec = reactive({
    model: '',
    placeholder: placeholders.value['patronym'],
    error: false,
    errorMsg: '',
    validated: false,
})

const emailSpec = reactive({
    model: '',
    placeholder: placeholders.value['email'],
    error: false,
    errorMsg: '',
    validated: false,
    regexp: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
})

const phoneSpec = reactive({
    model: '',
    placeholder: placeholders.value['phone'],
    error: false,
    errorMsg: '',
    validated: true,
    regexp: /^[+]?[0-9]{9,15}$/
})

const companySpec = reactive({
    model: '',
    placeholder: placeholders.value['company'],
    error: false,
    errorMsg: '',
    validated: false,
})

const textSpec = reactive({
    model: '',
    placeholder: placeholders.value['text'],
    error: false,
    errorMsg: '',
    validated: false,
})

let upload = ref(null);
let uploadedFiles = ref([])

const agreeCheckboxClick = () => {
    agreeActive.value = !agreeActive.value;
}

const changeCategory = (id) => {
    category.value = id;
}

const changeType = (val) => {
    type.value = val;
}

const validate = (obj) => {
    obj['error'] = false;
    obj['errorMsg'] = '';

    if (obj['model'] === '') {
        obj['error'] = true;
        obj['errorMsg'] = messages.value['empty'];
        obj['validated'] = false;
    } else {
        obj['validated'] = true;
    }
}

const validateWithRegexp = (obj, msg) => {
    obj['error'] = false;
    obj['errorMsg'] = '';
    obj['validated'] = false;

    if (obj['model'] === '') {
        obj['error'] = true;
        obj['errorMsg'] = messages.value['empty'];
        obj['validated'] = false;
    } else if (!(obj['regexp'].test(obj['model']))) {
        obj['error'] = true;
        obj['errorMsg'] = msg;
        obj['validated'] = false;
    } else {
        obj['validated'] = true;
    }
}

const validateSurname = () => {
    validate(surnameSpec)
}

const validateName = () => {
    validate(nameSpec)
}

const validateEmail = () => {
    validateWithRegexp(emailSpec, messages.value['email'])
}

const validatePhone = () => {
    validateWithRegexp(phoneSpec, messages.value['phone'])
}

const validateCompany = () => {
    validate(companySpec)
}


const validateText = () => {
    validate(textSpec)
}

const onSubmit = () => {
    successActive.value = false;
    errorActive.value = false;

    let formData = new FormData();

    formData.append('category', category.value);
    formData.append('surname', surnameSpec.model);
    formData.append('name', nameSpec.model);
    formData.append('patronym', patronymSpec.model);
    formData.append('email', emailSpec.model);
    formData.append('phone', phoneSpec.model);
    formData.append('company', companySpec.model);

    formData.append('type', type.value);
    formData.append('text', textSpec.model);

    for (let i = 0; i < upload.value.files.length; i++) {
        formData.append('files', upload.value.files[i])
    }

    api.postData(
        formData
    ).then((status) => {
        if (status === 200) {
            successActive.value = true;
        } else {
            errorActive.value = true;
        }
        console.log(status);
    });
}

const uploadFiles = () => {
    for (let i = 0; i < upload.value.files.length; i++) {
        uploadedFiles.value.push(upload.value.files[i])
    }
}

const deleteFile = (name) => {
    let dt = new DataTransfer();

    for (let i = 0; i < upload.value.files.length; i++) {
        if (upload.value.files[i].name !== name) {
            dt.items.add(upload.value.files[i]);
        }
    }
    upload.value.files = dt.files

    uploadedFiles.value = [];
    uploadFiles()
}

const closeResult = () => {
    successActive.value = false;
    errorActive.value = false;
}


watch([surnameSpec, nameSpec, emailSpec, phoneSpec, companySpec, agreeActive, type, textSpec], (newValue, oldValue) => {
    if (companySpec.validated && surnameSpec.validated && nameSpec.validated && phoneSpec.validated && emailSpec.validated && agreeActive.value && type.value && textSpec.validated) {
        formValidated.value = true;
    } else {
        formValidated.value = false;
    }
})
</script>

<style lang="scss">
.feedback-form {
    width: 100%;
    position: relative;
    padding: 118px 0 80px;
    background-color: #3B4C7B;

    @media screen and (max-width: $mobile) {
        padding: 60px 0;
    }

    &__main {
        width: 982px;
        margin: 0 auto;

        @media screen and (max-width: $mobile) {
            width: 100%;
            height: 100%;
            padding: 0 10px 25px 10px;
            border-radius: 0;
            overflow: scroll;
            box-sizing: border-box;
        }
    }

    &__title {
        font-weight: 400;
        font-size: 28px;
        line-height: 40px;
        text-align: center;
        color: #FFFFFF;
        margin: 0 auto 100px;

        @media screen and (max-width: $mobile) {
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 60px;
        }
    }

    &__category {
        display: flex;
        margin-bottom: 40px;

        @media screen and (max-width: $mobile) {
            flex-direction: column;
            margin-bottom: 20px;
        }

        &-desc {
            font-weight: 600;
            font-size: 18px;
            line-height: 140%;
            color: #FFFFFF;
            margin-right: 35px;

            @media screen and (max-width: $mobile) {
                font-size: 16px;
                margin-bottom: 20px;
            }
        }
    }

    &__row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;

        @media screen and (max-width: $mobile) {
            flex-direction: column;
            margin-bottom: 25px;

            & .form-input {
                margin-bottom: 25px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__checkbox {
        width: 100%;
        margin-bottom: 40px;

        @media screen and (max-width: $mobile) {
            & .checkbox {
                align-items: flex-start;
            }
        }
    }

    &__upload {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 50px;

        @media screen and (max-width: $mobile) {
            justify-content: flex-start;
            flex-direction: column-reverse;
            margin-bottom: 30px;
        }

        &-file-list {
            display: flex;
            flex-direction: row-reverse;
            flex-wrap: wrap;

            @media screen and (max-width: $mobile) {
                flex-direction: column;
            }
        }

        &-file-item {
            position: relative;
            width: 245px;
            height: 30px;
            background: #FFFFFF;
            padding: 2px 31px 3px 15px;
            box-sizing: border-box;
            border-radius: 5px;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            margin-left: 15px;
            margin-bottom: 13px;
            display: flex;
            align-items: center;
            color: #555555;

            @media screen and (max-width: $mobile) {
                margin: 0 auto 13px;
            }

            & span {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            &:nth-child(3n) {
                margin-left: 0;

                @media screen and (max-width: $mobile) {
                    margin: 0 auto 13px;
                }
            }

            &-cross {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 50%;
                right: 10px;
                width: 14px;
                height: 14px;
                padding: 0;
                background-color: transparent;
                transform: translateY(-50%);
                border: none;
                outline: none;
                cursor: pointer;

                & svg {
                    width: 8px;
                    height: 8px;
                }

                & svg path {
                    transition: all .3s ease;
                    fill: #555555;;
                }
            }
        }

        &-wrapper {
            display: flex;
            justify-content: flex-end;
            position: relative;
            height: 28px;
            margin-left: 20px;
            margin-bottom: 15px;
            cursor: pointer;

            @media screen and (max-width: $mobile) {
                margin-bottom: 20px;
            }
        }

        &-label {
            position: relative;
            width: fit-content;
            height: 25px;
            display: flex;
            align-items: center;
            border: none;
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            color: #FFFFFF;
            margin-left: auto;
            background-color: transparent;
            outline: none;
            cursor: pointer;
            padding: 0;
            z-index: 2;

            & svg {
                width: 14px;
                height: 23px;
                margin-right: 15px;

                & path {
                    transition: all .3s ease;
                }
            }

            @media screen and (max-width: $mobile) {
                margin-left: auto;
                margin-right: auto;
            }
        }

        &-input {
            position: absolute;
            top: 0;
            right: 0;
            width: 183px;
            height: 28px;
            padding: 0;
            border: 0;
            opacity: 0;
            cursor: pointer;
        }
    }

    &__submit {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 240px;
        height: 60px;
        background: #052262;
        border-radius: 8px;
        margin: 0 auto;
        padding: 0;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        color: #FFFFFF;
        border: none;
        outline: none;
        cursor: pointer;

        @media screen and (max-width: $mobile) {
            font-size: 16px;
            height: 50px;
        }

        &._disabled {
            background: #C7D0E2;
            pointer-events: none;
        }
    }

    //&__result {
    //    display: none;
    //    position: absolute;
    //    //top: 0;
    //    left: 0;
    //    flex-direction: column;
    //    align-items: center;
    //    width: 100%;
    //    height: 298px;
    //    padding: 32px 40px 24px;
    //    border-radius: 8px;
    //    box-sizing: border-box;
    //    background-color: #fff;
    //    opacity: 0;
    //    transition: opacity .2s ease;
    //    z-index: 1;
    //
    //    @media screen and (max-width: $tablet-middle) {
    //        height: 100%;
    //        border-radius: 0;
    //    }
    //
    //    &._active {
    //        display: flex;
    //        opacity: 1;
    //        animation: formAppearing .5s ease-in;
    //        animation-fill-mode: forwards;
    //    }
    //}
    //
    //&__result-wrapper {
    //    display: none;
    //    width: 100%;
    //    height: 100%;
    //    flex-direction: column;
    //    align-items: center;
    //    background-color: #fff;
    //    opacity: 0;
    //    transition: opacity .2s ease;
    //    z-index: 1;
    //
    //    & svg {
    //        margin-top: 60px;
    //        margin-bottom: 40px;
    //    }
    //
    //    &._active {
    //        display: flex;
    //        opacity: 1;
    //        animation: formAppearing .5s ease-in;
    //        animation-fill-mode: forwards;
    //    }
    //}
    //
    //&__result-text, {
    //    font-weight: 500;
    //    font-size: 20px;
    //    line-height: 32px;
    //    text-align: center;
    //    color: #333333;
    //    max-width: 340px;
    //    margin: 0 auto 0;
    //}
    //
    //&__cross {
    //    position: absolute;
    //    width: 13px;
    //    height: 13px;
    //    top: 19px;
    //    right: 23px;
    //    cursor: pointer;
    //    z-index: 5;
    //
    //    & svg path {
    //        transition: all .3s ease;
    //        fill: #C7D0E2;
    //    }
    //
    //    &:hover {
    //        & svg path {
    //            fill: darken(#C7D0E2, 10%);
    //        }
    //    }
    //}
}

@keyframes formAppearing {
    0% {
        opacity: 0;
        transform: scale(.7)
    }
    60% {
        opacity: 1;
        transform: scale(.95)
    }
    75% {
        opacity: 1;
        transform: scale(.92)
    }
    90% {
        opacity: 1;
        transform: scale(1.03)
    }
    to {
        opacity: 1;
        transform: scale(1)
    }
}

@keyframes formDisappearing {
    0% {
        transform: scale(1)
    }
    to {
        opacity: 0;
        transform: scale(1.2)
    }
}

</style>