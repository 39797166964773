<template>
    <PageHead
        :title="title"/>
</template>

<script setup>

import PageHead from "../../PageHead";
import {useStore} from "vuex";
import {computed} from "vue";

const name = "ExpertsHead"
const store = useStore();

const data = store.state.currentData;
const title = computed(() => data.experts.title);
</script>

<style scoped>

</style>