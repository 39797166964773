<template>
    <div class="form-select">
        <Multiselect
            :id="selectSpec.id"
            :placeholder="selectSpec.specs.placeholder"
            v-bind="selectSpec.specs"
            v-model="selectSpec.specs.value"
            @change="onChange"
        />
    </div>
</template>

<script setup>
import '@vueform/multiselect/themes/default.css'
import Multiselect from '@vueform/multiselect'
import {ref} from "vue";

    const name = "FormSelect";

const props = defineProps({
    selectSpec: {
        type: Object,
        required: true
    },
})

const emits = defineEmits(['change'])

//let typeVal = ref('video1');

const onChange = (val) => {
    emits('change', val)
}
</script>

<style lang="scss">
@import '../../styles/global.scss';

.form-select {


    &._disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    & .multiselect {
        height: 50px;
        background-color: #FFFFFF;
        border: 1px solid #C4C4C4;
        border-radius: 5px;
    }

    & .multiselect-placeholder {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        color: #555555;

        @media screen and (max-width: $mobile) {
            font-size: 16px;
        }
    }

    & .multiselect-caret {
        background-color: #555555;
    }

    & .multiselect-search {
        cursor: pointer;
    }

    & .multiselect-single-label {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;

        @media screen and (max-width: $mobile) {
            font-size: 16px;
        }
    }

    & .multiselect-options {
        max-height: 300px;
    }

    & .multiselect-option {
        min-height: 50px;
        position: relative;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;

        @media screen and (max-width: $mobile) {
            font-size: 14px;
        }

        &.is-selected {
            background-color: #FC7E2F;
            color: #fff;

            &.is-pointed {
                background-color: #FC7E2F;
                color: #fff;
            }
        }

        &.is-pointed {
            background-color: #EEEEEE;
        }

    }

    & .multiselect-dropdown {
        max-height: 300px;
        overflow: hidden;
    }
}
</style>