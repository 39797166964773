<template>
    <div class="form-radio">
        <ul class="form-radio__list">
            <li v-for="item in radioSpec"
                :key="item.id"
                class="form-radio__item">
                <input class="form-radio__item-name visually-hidden"
                       type="radio"
                       :name="item.name"
                       :id="item.id"
                       :checked="item.checked"
                       @change="onChange(item.label)">
                <label class="form-radio__item-label"
                       :for="item.id">
                    <span class="radio-indicator"></span>
                    <span>{{ item.label }}</span>
                </label>
            </li>
        </ul>
    </div>
</template>

<script setup>
const name = "FormRadio";

const props = defineProps({
    radioSpec: {
        type: Object,
        required: true
    }
});

const emits = defineEmits(['change'])

const onChange = (val) => {
    emits('change', val)
}


</script>

<style lang="scss">
.form-radio {
    width: 435px;

    @media screen and (max-width: $mobile) {
        width: 100%;
    }

    &__list {
        width: 100%;
        display: flex;
        justify-content: space-between;
        list-style: none;
        padding: 0;
        margin: 0;

        @media screen and (max-width: $mobile) {
            flex-direction: column;
            justify-content: flex-start;
        }
    }

    &__item {
        display: flex;
        position: relative;
        //margin-right: 10px;

        @media screen and (max-width: $mobile) {
            margin-bottom: 15px;
        }
    }

    &__item-name + label .radio-indicator {
        position: relative;
        width: 23px;
        height: 23px;
        background-color: transparent;
        border: 2px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 50%;
        margin-right: 8px;
        cursor: pointer;
    }

    &__item-name:checked + label .radio-indicator {

        &:before {
            position: absolute;
            content: '';
            width: 11px;
            height: 11px;
            border-radius: 50%;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
            background: #FC7E2F;
        }
    }

    &__item-label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        color: #FFFFFF;
        cursor: pointer;

        @media screen and (max-width: $mobile) {
            font-size: 14px;
        }
    }

}

</style>