import {createStore} from 'vuex'
// @ts-ignore
import data from "./data.js"

export default createStore({
    state: {
        currentData: data['ru'],
        currentLanguage: 'ru',
        userInput: '',
        popupOpened: false,
        asideMenuOpened: false,
    },
    mutations: {
        changeLanguage(state, val) {
            state.currentLanguage = val;
            state.currentData = data[val];
        },

        changeUserInput(state, val) {
            state.userInput = val;
        },

        togglePopup(state) {
            state.popupOpened = !state.popupOpened;
        },

        toggleAside(state) {
            state.asideMenuOpened = !state.asideMenuOpened;
        },

        closeAside(state) {
            state.asideMenuOpened = false;
        },
    },
    actions: {
        changeLanguage(context, val) {
            context.commit('changeLanguage', val)
        }
    },
    getters: {},
});





