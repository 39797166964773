<template>
<section class="seminar-schedule">
    <h2 class="seminar-schedule__title" v-html="title"></h2>
        <div v-for="(day, index) in list" :key="`item-${index}`" class="seminar-schedule__day">
            <p class="seminar-schedule__date" v-html="day.date"></p>
            <ul class="seminar-schedule__list">
                <li v-for="(item, ind) in day.content" :key="`item-${index}-${ind}`" class="seminar-schedule__item">
                    <p class="seminar-schedule__time" v-html="item.time"></p>
                    <div class="seminar-schedule__text">
                        <p v-for="(text, i) in item.text" :key="`item-${index}-${ind}-${i}`" class="seminar-schedule__text-item" v-html="text"></p>
                    </div>
                </li>
            </ul>
        </div>
    <a href="#reg" class="seminar-about__btn btn btn-blue">{{ btn }}</a>
</section>
</template>

<script setup>
import {useStore} from "vuex";
import {computed} from "vue";

const name ="SeminarSchedule"
const store = useStore();

const data = store.state.currentData;
const title = computed(() => data.seminar2.schedule.title);
const list = computed(() => data.seminar2.schedule.list);
const btn = computed(() => data.seminar2.schedule.btn);
</script>

<style lang="scss">
.seminar-schedule {
    padding-top: 120px;
    padding-bottom: 100px;

    @media screen and (max-width: $mobile) {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    &__title {
        font-weight: 600;
        font-size: 30px;
        line-height: 150%;
        color: #65ADEB;
        margin-bottom: 100px;

        @media screen and (max-width: $mobile) {
            font-size: 22px;
            margin-bottom: 20px;
        }
    }

    &__day {
        width: 100%;
        margin-bottom: 100px;

        @media screen and (max-width: $mobile) {
            margin-bottom: 40px;
        }
    }

    &__date {
        margin-bottom: 60px;
        font-weight: 600;
        font-size: 26px;
        line-height: 30px;
        text-align: center;
        text-transform: uppercase;
        color: #65ADEB;

        @media screen and (max-width: $mobile) {
            font-size: 18px;
            text-align: left;
            margin-bottom: 20px;
        }
    }

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;

        &:last-of-type {
            margin-bottom: 0;
        }

        @media screen and (max-width: $mobile) {
            flex-direction: column;
            margin-bottom: 20px;
        }
    }

    &__time {
        width: 250px;
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        text-align: right;
        color: #FC7E2F;

        @media screen and (max-width: $mobile) {
            width: 100%;
            font-size: 18px;
            margin-bottom: 6px;
            text-align: left;
        }

    }

    &__text {
        width: 880px;

        @media screen and (max-width: $mobile) {
            width: 100%;
        }

        &-item {
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            color: #2C2C2C;
            margin-bottom: 20px;

            @media screen and (max-width: $mobile) {
                font-size: 14px;
                line-height: 22px;
                margin-bottom: 15px;
            }


            &:first-of-type {
                font-weight: 600;
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            & span {
                font-weight: 600;
                color: rgba(101, 173, 235, 1)
            }
        }
    }
}
</style>