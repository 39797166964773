import { createApp } from 'vue'
import './styles/main.scss'
import App from './App.vue'
import DefaultLayout from "./layouts/Default.vue"
import router from './router'
import store from './store'
import vClickOutside from "click-outside-vue3";


createApp(App)
    .use(store)
    .use(router)
    .use(vClickOutside)
    .component("default-layout", DefaultLayout)
    .mount('#app');