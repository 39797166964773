<template>
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30 59.1667C13.8913 59.1667 0.833374 46.1088 0.833374 30C0.833374 13.8913 13.8913 0.833374 30 0.833374C46.1088 0.833374 59.1667 13.8913 59.1667 30C59.1667 46.1088 46.1088 59.1667 30 59.1667ZM27.0834 38.75V44.5834H32.9167V38.75H27.0834ZM27.0834 15.4167V32.9167H32.9167V15.4167H27.0834Z" fill="#D52047"/>
    </svg>
</template>

<script>
export default {
    name: "IconError"
}
</script>

<style scoped>

</style>