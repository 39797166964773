<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 18V20H3V18H21ZM17.05 3.55005L22 8.50005L17.05 13.45V3.55005ZM12 11V13H3V11H12ZM12 4.00005V6.00005H3V4.00005H12Z" fill="white"/>
    </svg>
</template>

<script>
export default {
    name: "IconOpenAside"
}
</script>

<style scoped>

</style>