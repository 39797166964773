<template>
<div class="experts">
    <div class="container">
        <ExpertsHead :key="language"/>
        <ExpertsDirector :key="language"/>
        <ExpertsContent :key="language"/>
    </div>
</div>
</template>

<script setup>
import ExpertsHead from "../components/blocks/Experts/ExpertsHead"
import ExpertsDirector from "../components/blocks/Experts/ExpertsDirector"
import ExpertsContent from "../components/blocks/Experts/ExpertsContent"
import {useStore} from "vuex";
import {computed} from "vue";

const name = "Experts"

const store = useStore();
const language = computed(() => store.state.currentLanguage);
</script>

<style scoped>

</style>