<template>
    <div class="experts-director">
        <div class="experts-director__wrapper">
            <img class="experts-director__img" src="../../../assets/images/photos/photo-dir.png" alt="">
            <p class="experts-director__name" v-html="fio"></p>
            <p class="experts-director__description" v-html="desc"></p>
        </div>
        <div class="experts-director__text">
            <p v-for="item in text" v-html="item"></p>
        </div>
    </div>
</template>

<script setup>

import {useStore} from "vuex";
import {computed} from "vue";

const name = "ExpertsDirector"
const store = useStore();

const data = store.state.currentData;
const fio = computed(() => data.experts.director.fio);
const desc = computed(() => data.experts.director.desc);
const text = computed(() => data.experts.director.text);

</script>

<style lang="scss">
.experts-director {
    padding-top: 100px;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: $mobile) {
        justify-content: flex-start;
        flex-direction: column;
        padding-top: 60px;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 380px;

        @media screen and (max-width: $mobile) {
            width: 100%;
            margin-bottom: 30px;
        }
    }

    &__img {
        display: block;
        width: 180px;
        height: 180px;
        margin-bottom: 34px;

        @media screen and (max-width: $mobile) {
            margin-bottom: 25px;
        }
    }

    &__name {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: #2C2C2C;
        margin-bottom: 22px;

        @media screen and (max-width: $mobile) {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 15px;
        }
    }

    &__description {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #2C2C2C;

        @media screen and (max-width: $mobile) {
            font-size: 16px;
            line-height: 20px;
        }
    }

    &__text {
        width: 780px;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #2C2C2C;

        & p {
            text-align: justify;
            margin-bottom: 20px;
        }

        @media screen and (max-width: $mobile) {
            width: 100%;
            font-size: 14px;
            line-height: 22px;

            & p {
                margin-bottom: 15px;
            }
        }

    }
}
</style>