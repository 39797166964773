<template>
    <div class="support">
        <div class="container">
            <SupportHead :key="language"/>
            <Search :key="language"/>
            <Topics :key="language"/>
        </div>
        <FeedbackForm :key="language"/>
    </div>
</template>

<script setup>
import SupportHead from "../components/blocks/Support/SupportHead";
import Search from "../components/blocks/Support/Search";
import Topics from "../components/blocks/Support/Topics";
import FeedbackForm from "../components/FeedbackForm2";
import {computed} from "vue";
import {useStore} from "vuex";

const name = "Support";
const store = useStore();

const language = computed(() => store.state.currentLanguage);

store.commit('changeUserInput', '')

</script>

<style lang="scss">

</style>