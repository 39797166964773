<template>
    <div class="faq-item" ref="faqItem">
        <div class="faq-item__top">
            <h2 class="faq-item__name" v-html="itemSpec.name" @click="openItem"></h2>
            <button class="faq-item__toggle"
                    :class="{'_opened': opened}"
                    @click="openItem">
                <IconArrow/>
            </button>
        </div>
        <div class="faq-item__bottom" :class="{'_opened': opened}">
            <p v-for="textItem in itemSpec.text" class="faq-item__text" v-html="textItem"></p>
            <div class="faq-item__wrapper">
                <p class="faq-item__category">{{ itemSpec.category }}</p>
                <div class="faq-item__tags">
                    <div v-for="tag in itemSpec.tags" class="faq-item__tags-item">{{ tag }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import IconArrow from "@/assets/images/vue-icons/IconArrow";
import {computed, ref} from "vue";

const name = "FAQItem";

const props = defineProps({
    itemSpec: {
        type: Object,
        required: true
    }
})

let faqItem = ref(null)
let opened = ref(false);

const openItem = () => {
    opened.value = !opened.value;

    // if (!opened.value) {
    //     faqItem.value.scrollIntoView({block: "start", behavior: "auto"});
    // }
}

</script>

<style lang="scss">
.faq-item {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 30px 46px 30px 30px;
    background: #FFFFFF;
    border: 1px solid #C7D0E2;
    border-radius: 8px;
    margin-bottom: 20px;

    @media screen and (max-width: $mobile) {
        padding: 15px 35px 15px 15px;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    &__name {
        font-weight: 400;
        font-size: 22px;
        line-height: 34px;
        color: #242424;
        margin: 0;
        cursor: pointer;

        @media screen and (max-width: $mobile) {
            font-size: 15px;
            line-height: 18px;
        }
    }

    &__toggle {
        display: flex;
        justify-content: center;
        position: absolute;
        top: 30px;
        right: 0;
        width: 59px;
        height: calc(100% - 60px);
        box-sizing: border-box;
        padding: 13px 0 0;
        background-color: transparent;
        cursor: pointer;
        border: none;
        outline: none;

        @media screen and (max-width: $mobile) {
            top: 0;
            width: 25px;
            height: 100%;
            padding: 0;
            align-items: center;
        }

        & svg {
            width: 13px;
            height: 8px;
            transition: all .3s ease;

            @media screen and (max-width: $mobile) {
                width: 10px;
            }
        }

        &._opened {

            & svg {
                transform: rotate(180deg);
            }
        }
    }

    &__bottom {
        max-height: 0;
        overflow: hidden;
        transition: max-height .3s ease-in-out;

        &._opened {
            max-height: 1400px;

            @media screen and (max-width: $mobile) {
                max-height: 3000px;
            }
        }
    }

    &__text {
        font-weight: 400;
        font-size: 18px;
        line-height: 34px;
        color: #242424;
        text-align: justify;
        margin-bottom: 25px;

        @media screen and (max-width: $mobile) {
            font-size: 14px;
            line-height: 22px;
        }

        &:first-of-type {
            margin-top: 30px;
        }

        &:last-of-type {
            margin-bottom: 50px;
        }

        & a {
            color: #0033A0;
            transition: color .3s ease;

            &:hover {
                color: lighten(#0033A0, 20%)
            }
        }
    }

    &__category {
        position: relative;
        font-weight: 600;
        font-size: 18px;
        line-height: 34px;
        color: #242424;
        padding-top: 18px;
        padding-bottom: 20px;

        @media screen and (max-width: $mobile) {
            font-size: 14px;
            line-height: 22px;
        }

        &:before {
            position: absolute;
            content: '';
            top: 0;
            left: -30px;
            width: calc(100% + 76px);
            height: 1px;
            background-color: #C7D0E2;
        }
    }

    &__tags {
        display: flex;

        @media screen and (max-width: $mobile) {
            flex-wrap: wrap;
        }
    }

    &__tags-item {
        width: fit-content;
        box-sizing: border-box;
        padding: 3px 5px;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #FFFFFF;
        background-color: #FC7E2F;
        border-radius: 5px;
        margin-right: 12px;
        cursor: default;

        @media screen and (max-width: $mobile) {
            font-size: 11px;
            line-height: 15px;
            margin-right: 8px;
            margin-bottom: 8px;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
}
</style>