<template>
    <div class="exhibitions">
        <div class="container">
            <div class="exhibitions__head"></div>
            <ExhibitionsAbout :key="language"/>
            <ExhibitionsList :key="language"/>
        </div>
    </div>
</template>

<script setup>
import ExhibitionsAbout from "../components/blocks/Exhibitions/ExhibitionsAbout"
import ExhibitionsList from "../components/blocks/Exhibitions/ExhibitionsList"
import {useStore} from "vuex";
import {computed} from "vue";

const name = "Exhibitions";

const store = useStore();
const language = computed(() => store.state.currentLanguage);

</script>

<style lang="scss">
.exhibitions {
    padding-bottom: 140px;

    @media screen and (max-width: $mobile) {
        padding-bottom: 60px;
    }

    &__head {
        width: 100%;
        height: 430px;
        background-image: url("../assets/images/exhibitions-head.png");
        background-size: cover;
        background-repeat: no-repeat;

        @media screen and (max-width: $mobile) {
            height: calc(100vw * 0.4);
        }
    }
}
</style>