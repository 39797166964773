<template>
    <div class="history">
        <h2 class="history__title h2" v-html="title"></h2>
        <p class="history__text" v-html="text1"></p>
        <div class="history__main-logos">
            <a href="http://www.vavt.ru/" class="history__logo _vavt" target="_blank"></a>
            <a href="https://dica.am/ru/" class="history__logo _corp" target="_blank"></a>
        </div>
        <p class="history__text" v-html="text2"></p>
        <div class="history__add-logos">
            <a href="https://www.economy.gov.ru/" class="history__logo _mineconom" target="_blank"></a>
            <a href="https://www.mineconomy.am/ru" class="history__logo _armeconom" target="_blank"></a>
        </div>
    </div>
</template>

<script setup>
import {computed} from "vue";
import {useStore} from "vuex";

const name = "History";

const store = useStore();
const data = store.state.currentData;
const title = computed(() => data.main.history.title);
const text1 = computed(() => data.main.history.text1);
const text2 = computed(() => data.main.history.text2);
</script>

<style lang="scss">
.history {
    padding-top: 100px;

    @media screen and (max-width: $mobile) {
        padding-top: 60px;
    }

    &__text {
        width: 980px;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #2C2C2C;
        margin: 0 auto 58px;
        text-align: justify;

        & a {
            color: rgba(5, 34, 98, 1);
            text-decoration: none;
            font-weight: 700;
            transition: all .3s ease;

            @media screen and (min-width: $tablet-middle) {
                &:hover {
                    color: lighten(rgba(5, 34, 98, 1), 20%)
                }
            }
        }

        @media screen and (max-width: $mobile) {
            width: 100%;
            font-size: 14px;
            line-height: 26px;
            margin-bottom: 30px;
        }
    }

    &__main-logos {
        width: 980px;
        padding: 0 90px;
        box-sizing: border-box;
        margin: 0 auto 62px;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: $mobile) {
            width: 305px;
            flex-direction: column;
            padding: 0;
            margin-bottom: 30px;
        }
    }

    &__add-logos {
        width: 980px;
        padding: 0 104px;
        box-sizing: border-box;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: $mobile) {
            width: 305px;
            flex-direction: column;
            padding: 0;
        }
    }

    &__logo {
        display: block;
        background-size: cover;
        background-repeat: no-repeat;

        @media screen and (max-width: $mobile) {
            margin-bottom: 25px;
        }

        &._vavt {
            width: 305px;
            height: 80px;
            background-image: url("../../../assets/images/logo-vavt.svg");

            @media screen and (max-width: $mobile) {
                width: 235px;
                height: 61px;
            }
        }

        &._corp {
            width: 352px;
            height: 77px;
            background-image: url("../../../assets/images/logo-corp.svg");

            @media screen and (max-width: $mobile) {
                width: 271px;
                height: 59px;
            }
        }

        &._mineconom {
            width: 396px;
            height: 61px;
            background-image: url("../../../assets/images/logo-mineconom.svg");

            @media screen and (max-width: $mobile) {
                width: 305px;
                height: 47px;
            }
        }

        &._armeconom {
            width: 323px;
            height: 61px;
            background-image: url("../../../assets/images/logo-armeconom.svg");

            @media screen and (max-width: $mobile) {
                width: 248px;
                height: 47px;
            }
        }
    }

}
</style>