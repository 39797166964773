export function isUndefined(val) {
    return (val === undefined);
}

export function isNull(val) {
    return ((val === null) || (val === undefined));
}

export function pluralize(n, titles) {
    return titles[n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
}


export function toggleArrayElement(arr, arg) {
    let index = arr.indexOf(arg);

    if (index === -1) {
        arr.push(arg);
    } else {
        arr.splice(index, 1);
    }
}

export function checkPlaceholder (arr, wordsArr, defaultPlaceholder, isCompany = false) {
    if (arr.length > 0) {
        let letter;
        if (arr.length > 1) {
            letter = 'о'
        } else {
            letter = 'а'
        }

        if (isCompany) {
            letter = 'о'
        }
        return `Выбран${letter} ${arr.length} ${pluralize(arr.length, wordsArr)}`;
    } else {
        return defaultPlaceholder;
    }
}

export function sortArr(arr, field) {
    arr.sort(function (a, b) {
        let nA = a[field];
        let nB = b[field];
        let nameA = '';
        let nameB = '';
        if (nA) {
            nameA = a[field].toLowerCase();
        }

        if (nB) {
            nameB = b[field].toLowerCase();
        }


        if (nameA < nameB)
            return -1
        if (nameA > nameB)
            return 1
        return 0
    })
}

export function blockBody () {
    const body = document.querySelector('body');
    body.classList.toggle('blocked');
}