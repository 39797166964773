<template>
    <div>
        <component :is="'default-layout'">
            <router-view/>
        </component>
        <Popup/>
    </div>
</template>

<script setup>
import Popup from "./components/Popup";
import {useStore} from "vuex";
const name = "App";

const store = useStore();

let lang = localStorage.getItem('lang');

if (lang) {
    store.dispatch('changeLanguage', lang)
} 

</script>

<style lang="scss">

</style>

