<template>
    <div class="documents">
        <h2 class="documents__title h2" v-html="title"></h2>
        <ul class="documents__list">
            <li v-for="item in list" class="documents__item">
                <p class="documents__name" v-html="item.name"></p>
                <div class="documents__wrapper">
                    <a class="documents__open" :href="createOpenLink(item.link)" target="_blank"><IconOpenPdf/></a>
                    <a class="documents__download" :href="createDownloadLink(item.link)" download><IconDownloadPdf/></a>
                </div>
            </li>
        </ul>
    </div>
</template>

<script setup>
import IconOpenPdf from "../../../assets/images/vue-icons/IconOpenPdf";
import IconDownloadPdf from "../../../assets/images/vue-icons/IconDownloadPdf";
import {useStore} from "vuex";
import {computed} from "vue";
import {currentHost} from "@/api/hosts";


const name = "Documents";
const store = useStore();

const data = store.state.currentData;
const title = computed(() => data.main.documents.title);
const list = computed(() => data.main.documents.list);

const createDownloadLink = (link) => {
    return `${currentHost}download/${link}`
}

const createOpenLink = (link) => {
    console.log(`${currentHost}send/${link}`)
    return `${currentHost}send/${link}`
}
</script>

<style lang="scss">
.documents {
    padding-top: 100px;

    @media screen and (max-width: $mobile) {
        padding-top: 60px;
    }

    &__list {
        width: 980px;
        list-style: none;
        padding: 0;
        margin: 0 auto;

        @media screen and (max-width: $mobile) {
            width: 100%;
        }
    }

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        width: 100%;
        min-height: 90px;
        border: 1px solid #D9D9D9;
        border-radius: 5px;
        padding: 30px;
        box-sizing: border-box;
        color: #2C2C2C;
        margin-bottom: 30px;

        @media screen and (max-width: $mobile) {
            min-height: 60px;
            padding: 15px;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__name {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #2C2C2C;

        @media screen and (max-width: $mobile) {
            font-size: 13px;
            line-height: 15px;
        }
    }

    &__wrapper {
        width: 78px;
        height: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: $mobile) {
            width: 50px;
            min-width: 50px;
            margin-left: 10px;
        }
    }

    &__open,
    &__download {
        width: 24px;
        height: 24px;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;

        @media screen and (max-width: $mobile) {
            width: 18px;
            height: 18px;
        }
    }
}
</style>