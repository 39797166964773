<template>
<section class="seminar-partners">
    <img v-for="item in imgList" class="seminar-partners__img" :src="getImgUrl(item.img)" alt="">
</section>
</template>

<script setup>
    import {useStore} from "vuex";
    import {computed} from "vue";

    const name = "SeminarPartners"
    const store = useStore();
    const data = store.state.currentData;

    const imgList = computed(() => data.seminar2.partners.list);

    const getImgUrl = (pic) => {
        return require('../../../assets/images/seminar-partners/' + pic);
    };
</script>

<style lang="scss">
.seminar-partners {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 155px 40px 151px;
    box-sizing: border-box;

    @media screen and (max-width: $mobile) {
        padding: 60px 0 0px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    &__img {
        @media screen and (max-width: $mobile) {
            display: block;
            margin-bottom: 65px;
        }
    }
}
</style>