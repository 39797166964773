<template>
    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1.33862" width="20" height="20" rx="4" stroke="white" stroke-width="2"/>
        <path d="M8.75 13.7604L5.97465 10.9851L5.62109 10.6315L5.26754 10.9851L4.21285 12.0398L3.8593 12.3933L4.21285 12.7469L8.39645 16.9305L8.75 17.284L9.10355 16.9305L18.1036 7.93046L18.4571 7.5769L18.1036 7.22335L17.0489 6.16866L16.6953 5.81511L16.3418 6.16866L8.75 13.7604Z" fill="#FC7E2F" stroke="#FC7E2F"/>
    </svg>
</template>

<script>
export default {
    name: "IconCheck"
}
</script>

<style scoped>

</style>