<template>
    <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.364 4.95L11.314 0L12.728 1.414L6.364 7.778L0 1.414L1.414 0L6.364 4.95Z" fill="#C7D0E2"/>
    </svg>
</template>

<script>
export default {
    name: "IconArrow"
}
</script>

<style scoped>

</style>