<template>
    <section class="seminar-plan">
        <h2 class="seminar-plan__title" v-html="title"></h2>
        <ul class="seminar-plan__list">
            <li v-for="(item, index) in list" :key="`item-${index}`" class="seminar-plan__item">
                <p class="seminar-plan__name" v-html="item"></p>
            </li>
        </ul>
    </section>
</template>

<script setup>

import {useStore} from "vuex";
import {computed} from "vue";

const name = "SeminarPlan"

const store = useStore();

const data = store.state.currentData;
const title = computed(() => data.seminar2.plan.title);
const list = computed(() => data.seminar2.plan.list);

</script>

<style lang="scss">
.seminar-plan {
    padding-top: 70px;

    @media screen and (max-width: $mobile) {
        padding-top: 60px;
    }

    &__title {
        font-weight: 600;
        font-size: 28px;
        line-height: 30px;
        color: #3E91D8;
        margin-top: 0;
        margin-bottom: 50px;

        @media screen and (max-width: $mobile) {
            font-size: 22px;
            margin-bottom: 30px;
        }
    }

    &__list {
        list-style: none;
        padding: 0;
    }

    &__item {
        position: relative;
        padding-left: 34px;
        box-sizing: border-box;
        display: flex;
        margin-bottom: 50px;

        @media screen and (max-width: $mobile) {
            flex-direction: column;
            margin-bottom: 20px;
            padding-left: 20px;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        &:before {
            position: absolute;
            content: '';
            width: 16px;
            height: 16px;
            top: 7px;
            left: 0;
            background-color: #65ADEB;

            @media screen and (max-width: $mobile) {
                width: 8px;
                height: 8px;
            }
        }
    }

    &__name {
        width: 100%;
        margin-right: 20px;
        font-weight: 700;
        font-size: 20px;
        line-height: 170%;
        color: #2C2C2C;

        @media screen and (max-width: $mobile) {
            width: 100%;
            min-width: unset;
            font-size: 14px;
            margin-right: 0;
        }
    }
}
</style>