<template>
    <div class="main-head">
        <div class="main-head__wrapper"></div>
        <h1 class="main-head__title" v-html="title"></h1>
        <p class="main-head__subtitle" v-html="subtitle"></p>
    </div>
</template>

<script setup>
import {useStore} from "vuex";
import {computed} from "vue";

const name = "MainHead"
const store = useStore();

const data = store.state.currentData;
const title = computed(() => data.main.head.title);
const subtitle = computed(() => data.main.head.subtitle);

</script>

<style lang="scss">
.main-head {
    width: 100%;
    height: 475px;
    padding: 62px 680px 55px 53px;
    border-radius: 10px;
    box-sizing: border-box;
    background-image: url("../../../assets/images/main-head.png");
    background-size: cover;
    background-repeat: no-repeat;
    color: #2C2C2C;

    @media screen and (max-width: $mobile) {
        background-image: none;
        padding: 0;
        height: auto;
    }

    &__wrapper {
        display: none;

        @media screen and (max-width: $mobile) {
            display: block;
            width: 100%;
            height: 130px;
            border-radius: 5px;
            margin-bottom: 30px;
            background-image: url("../../../assets/images/main-head-mobile.png");
            background-size: cover;
            background-repeat: no-repeat;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 26px;
        line-height: 44px;
        margin-top: 0;
        margin-bottom: 49px;

        @media screen and (max-width: $mobile) {
            font-size: 20px;
            line-height: 27px;
            margin-bottom: 20px;
        }
    }

    &__subtitle {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        max-width: 450px;

        @media screen and (max-width: $mobile) {
            font-size: 14px;
            line-height: 26px;
        }
    }

}
</style>