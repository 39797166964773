<template>
    <div class="seminar">
        <div class="container">
            <div class="seminar__head"></div>
            <SeminarAbout :key="language"/>
            <SeminarObjective :key="language"/>
            <SeminarPlan :key="language"/>
            <SeminarInfo :key="language"/>
            <SeminarSchedule :key="language"/>
        </div>
        <SeminarRegistration :key="language"/>
        <div class="container">
            <SeminarPartners :key="language"/>
        </div>
    </div>
</template>

<script setup>
import SeminarAbout from "../components/blocks/Seminar2/SeminarAbout";
import SeminarObjective from "../components/blocks/Seminar2/SeminarObjective";
import SeminarPlan from "../components/blocks/Seminar2/SeminarPlan";
import SeminarInfo from "../components/blocks/Seminar2/SeminarInfo";
import SeminarSchedule from "../components/blocks/Seminar2/SeminarSchedule"
import SeminarRegistration from "../components/blocks/Seminar2/SeminarRegistration";
import SeminarPartners from "../components/blocks/Seminar2/SeminarPartners";

import {useStore} from "vuex";
import {computed} from "vue";

const name = "Seminar2";
const store = useStore();
const language = computed(() => store.state.currentLanguage);

</script>

<style lang="scss">
.seminar {

    &__head {
        width: 100%;
        height: 430px;
        background-image: url("../assets/images/seminar-head2.png");
        background-size: cover;
        background-repeat: no-repeat;

        @media screen and (max-width: $mobile) {
            height: calc(100vw * 0.4);
        }
    }
}
</style>