<template>
    <header class="header">
        <div class="header__container container">
            <div class="header__mobile" @click.stop="openMobileMenu">
                <button class="header__burger">
                    <IconBurger/>
                </button>
            </div>
            <div class="header__lang" @click.stop="changeLanguage">
                <IconArmenia v-if="language==='ru'"/>
                <IconRussia v-else/>
            </div>
            <router-link class="header__logo" :to="{name: 'Main'}">
                <IconLogo/>
            </router-link>
        </div>
    </header>
</template>

<script setup>
import IconLogo from "@/assets/images/vue-icons/IconLogo";
import IconBurger from "@/assets/images/vue-icons/IconBurger";
import {computed, ref} from "vue";
import {useStore} from "vuex";
import IconArmenia from "@/assets/images/vue-icons/navigation/IconArmenia";
import IconRussia from "@/assets/images/vue-icons/navigation/IconRussia";

const store = useStore();

const language = computed(() => store.state.currentLanguage);

const openMobileMenu = () => {
    store.commit('toggleAside');
}

const changeLanguage = () => {
    if (language.value === 'ru') {
        store.dispatch('changeLanguage', 'arm')
    } else {
        store.dispatch('changeLanguage', 'ru')
    }
    localStorage.removeItem('lang');
    localStorage.setItem('lang', language.value)
}
</script>

<style lang="scss">
.header {
    position: relative;
    width: 100%;
    padding: 30px 0;
    background: #FFFFFF;
    color: #2C2C2C;
    z-index: 10;

    @media screen and (max-width: $mobile) {
        padding: 22px 0;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        //@media screen and (max-width: $mobile) {
        //    justify-content: space-between;
        //}
    }

    &__logo {
        width: 300px;
        height: 51px;
        display: flex;
        align-items: center;
        text-decoration: none;

        @media screen and (max-width: $mobile) {
            width: 200px;
            height: 33.75px;
            pointer-events: none;

            & svg {
                width: 200px;
                height: 33.75px;
            }
        }
    }

    &__mobile {
        display: none;

        @media screen and (max-width: $mobile) {
            display: block;
        }
    }

    &__burger {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        padding: 0;
        border: none;
        background-color: transparent;
        outline: none;
        cursor: pointer;
    }

    &__lang {
        //display: none;
        width: 30px;
        height: 20px;
        cursor: pointer;

        @media screen and (max-width: $mobile) {
            display: block;
        }
    }
}
</style>