<template>
    <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 13.3334H30V20H0V13.3334Z" fill="#C23729"/>
        <path d="M0 6.66663H30V13.3333H0V6.66663Z" fill="#061B79"/>
        <path d="M0 0H30V6.66667H0V0Z" fill="#FFFFFF"/>
        <rect x="0.5" y="0.5" width="29" height="19" stroke="#9FD1FC"/>
    </svg>
</template>

<script>
export default {
    name: "IconRussia"
}
</script>

<style scoped>

</style>