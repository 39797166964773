<template>
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 17.3052V20.3052C3 21.4097 3.89543 22.3052 5 22.3052H19C20.1046 22.3052 21 21.4097 21 20.3052V17.3052" stroke="#9A9A9A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8 12.3052L12 16.3052L16 12.3052" stroke="#9A9A9A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 2.30518V16.3052" stroke="#9A9A9A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: "IconDownloadPdf"
}
</script>

<style scoped>

</style>