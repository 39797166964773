<template>
    <div class="main">
        <div class="container">
            <MainHead :key="language"/>
            <History :key="language"/>
            <Goals :key="language"/>
            <Tasks :key="language"/>
            <Documents :key="language"/>
            <Partners :key="language"/>
            <Projects :key="language"/>
            <a href="https://eaeu.economy.gov.ru/macroeconomic_indicators" class="main__integration" target="_blank">
                <span v-if="language === 'ru'">Евразийская интеграция в&nbsp;цифрах</span>
                <span v-else>Եվրասիական ինտեգրումը թվերով</span>
            </a>
        </div>
        <FeedbackForm :key="language"/>
    </div>
</template>

<script setup>
import MainHead from "../components/blocks/Main/MainHead";
import History from "../components/blocks/Main/History";
import Goals from "../components/blocks/Main/Goals";
import Tasks from "../components/blocks/Main/Tasks";
import Documents from "../components/blocks/Main/Documents";
import Projects from "../components/blocks/Main/Projects";
import Partners from "../components/blocks/Main/Partners";
import FeedbackForm from "../components/FeedbackForm2";
import {useStore} from "vuex";
import {computed} from "vue";

const name = "Main";

const store = useStore();
const language = computed(() => store.state.currentLanguage);

</script>

<style lang="scss">
.main {

    &__integration {
        display: block;
        width: 980px;
        height: 254px;
        margin: 0 auto 170px;
        padding: 55px 680px 54px 41px;
        box-sizing: border-box;
        text-decoration: none;
        background-image: url("../assets/images/integration.png");
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 5px;

        @media screen and (max-width: $mobile) {
            display: flex;
            align-items: center;
            width: 100%;
            height: calc(100vw * 0.3);
            min-height: 82px;
            padding: 17px 219px 17px 13px;
            margin-bottom: 80px;
            background-position-x: 100%;
        }

        & span {
            display: block;
            font-weight: 700;
            font-size: 40px;
            line-height: 120%;
            color: #FFFFFF;

            @media screen and (max-width: $mobile) {
                font-size: 14px;
                max-width: 85px;
            }
        }
    }
}
</style>