<template>
    <section class="seminar-info">
        <h2 class="seminar-info__title" v-html="title"></h2>
        <p class="seminar-info__desc" v-html="desc"></p>
        <ul class="seminar-info__list">
            <li v-for="(item, index) in list" :key="`item-${index}`" class="seminar-info__item">
                <div class="seminar-info__icon" :style="{backgroundImage: 'url('+ getImgUrl(item.icon) + ')'}"></div>
                <p class="seminar-info__name" v-html="item.name"></p>
                <div class="seminar-info__text">
                    <p v-for="(text, ind) in item.text" :key="`item-${index}-${ind}`" class="seminar-info__text-item"
                       v-html="text"></p>
                </div>
            </li>
        </ul>
        <p class="seminar-info__disclaimer" v-html="disclaimer"></p>
        <a href="#reg" class="seminar-info__btn btn btn-blue">{{ btn }}</a>
    </section>
</template>

<script setup>
import {useStore} from "vuex";
import {computed} from "vue";

const name = "SeminarInfo";

const store = useStore();

const data = store.state.currentData;
const title = computed(() => data.seminar2.info.title);
const desc = computed(() => data.seminar2.info.desc);
const list = computed(() => data.seminar2.info.list);
const disclaimer = computed(() => data.seminar2.info.disclaimer);
const btn = computed(() => data.seminar2.info.btn);

const getImgUrl = (pic) => {
    return require('../../../assets/images/seminar-icons/' + pic);
};
</script>

<style lang="scss">
.seminar-info {
    padding-top: 120px;

    @media screen and (max-width: $mobile) {
        padding-top: 60px;
    }

    &__title {
        font-weight: 600;
        font-size: 30px;
        line-height: 150%;
        color: #3E91D8;
        margin-bottom: 50px;
        margin-top: 0;

        @media screen and (max-width: $mobile) {
            font-size: 22px;
            margin-bottom: 30px;
        }
    }

    &__desc {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #2C2C2C;
        margin-bottom: 120px;

        @media screen and (max-width: $mobile) {
            font-size: 14px;
            margin-bottom: 35px;
            line-height: 22px;
        }
    }

    &__list {
        display: flex;
        list-style: none;
        padding: 0;
        margin-bottom: 120px;

        @media screen and (max-width: $mobile) {
            flex-direction: column;
            margin-bottom: 10px;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 380px;

        @media screen and (max-width: $mobile) {
            width: 100%;
            margin-bottom: 40px;
        }

        &:nth-child(3n+2) {
            & .seminar-info__icon {
                background-color: #3B4C7B;
            }

            & .seminar-info__name {
                color: #3B4C7B;
            }
        }

        &:nth-child(3n+3) {
            & .seminar-info__icon {
                background-color: #65ADEB;
            }

            & .seminar-info__name {
                color: #65ADEB;
            }
        }
    }

    &__icon {
        width: 90px;
        height: 90px;
        background-repeat: no-repeat;
        margin-bottom: 49px;
        background-color: #FC7E2F;
        background-position: center;
        border-radius: 5px;

        @media screen and (max-width: $mobile) {
            width: 70px;
            height: 70px;
            background-size: 55%;
            margin-bottom: 20px;
        }
    }

    &__name {
        min-height: 60px;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: #FC7E2F;
        margin-bottom: 40px;

        @media screen and (max-width: $mobile) {
            font-size: 16px;
            min-height: unset;
            margin-bottom: 20px;
        }
    }

    &__text-item {
        font-size: 19px;
        line-height: 30px;
        text-align: center;
        color: #2C2C2C;

        &:first-of-type {
            margin-bottom: 22px;

            @media screen and (max-width: $mobile) {
                margin-bottom: 10px;
            }
        }

        @media screen and (max-width: $mobile) {
            font-size: 14px;
            line-height: 22px;
        }
    }

    &__disclaimer {
        font-weight: 400;
        font-size: 20px;
        line-height: 140%;
        text-align: center;
        color: #000000;
        margin-bottom: 40px;

        & span {
            color: rgba(59, 76, 123, 1)
        }

        @media screen and (max-width: $mobile) {
            font-size: 14px;
            margin-bottom: 20px;
        }
    }

    &__btn {
        margin: 0 auto;
    }
}
</style>