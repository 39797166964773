<template>
    <div class="exhibitions-about">
        <h1 class="exhibitions-about__title" v-html="title"></h1>
        <p class="exhibitions-about__desc" v-html="desc"></p>
    </div>
</template>

<script setup>
import {useStore} from "vuex";
import {computed} from "vue";

const name = "ExhibitionsAbout"
const store = useStore();

const data = store.state.currentData;
const title = computed(() => data.exhibitions.title);
const desc = computed(() => data.exhibitions.desc);
</script>

<style lang="scss">
.exhibitions-about {
    padding: 110px 100px 0;

    @media screen and (max-width: $mobile) {
        padding: 40px 0;
    }

    &__title {
        font-weight: 400;
        font-size: 28px;
        line-height: 30px;
        text-align: center;
        color: #052262;
        margin-bottom: 84px;
        margin-top: 0;

        @media screen and (max-width: $mobile) {
            font-size: 18px;
            line-height: 20px;
            margin-bottom: 40px;
        }
    }

    &__desc {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #2C2C2C;
        margin-bottom: 87px;

        @media screen and (max-width: $mobile) {
            font-size: 14px;
            line-height: 22px;
            text-align: justify;
            margin-bottom: 0;
        }
    }
}
</style>