let foreignBusinessHosts = {
    sandbox: 'http://10.8.0.11:8568/api/',
    local: 'http://localhost:8000/api/',
    prod_1: 'https://csda.am/api/',
    prod_2: 'https://fb.vavt.ru/api/'
};

let currentHost = foreignBusinessHosts[process.env.VUE_APP_DEPLOYMENT_ENV];

export {currentHost};
