import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import Main from "../views/Main.vue";
import Support from "../views/Support.vue";
import Experts from "../views/Experts.vue";
import Contacts from "../views/Contacts.vue";
import FAQ from "../views/FAQ.vue"
import Seminar from "../views/Seminar2.vue"
import Exhibitions from "../views/Exhibitions.vue"

const publicPath = process.env.VUE_APP_DEPLOYMENT_ENV === 'prod_1' ? '/test' : '/';

const routes: Array<RouteRecordRaw> = [

  {
    name: 'Main',
    path: '/',
    component: Main,
    meta:{
      layout: "default-layout"
    }
  },

  {
    name: 'Support',
    path: `/support`,
    component: Support,
    meta:{
      layout: "default-layout"
    }
  },

  {
    name: 'Experts',
    path: `/experts`,
    component: Experts,
    meta:{
      layout: "default-layout"
    }
  },

  {
    name: 'Contacts',
    path: `/contacts`,
    component: Contacts,
    meta:{
      layout: "default-layout"
    }
  },

  {
    name: 'FAQ',
    path: `/faq`,
    component: FAQ,
    meta:{
      layout: "default-layout"
    }
  },

  {
    name: 'Seminar',
    path: `/seminar`,
    component: Seminar,
    meta:{
      layout: "default-layout"
    }
  },

  {
    name: 'Exhibitions',
    path: `/exhibitions`,
    component: Exhibitions,
    meta:{
      layout: "default-layout"
    }
  },
]

const router = createRouter({
  history: createWebHistory(publicPath),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
