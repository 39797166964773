<template>
    <div class="search">
        <div class="search__wrapper">
            <input v-model="input"
                   type="text"
                   class="search__input"
                   :placeholder="placeholder"
            @input="changeUserInput">
            <ClearButton
                :user-input="input"
                @click="clear"/>
        </div>
<!--        <button class="search__btn" @click="changeUserInput">Искать</button>-->
    </div>
</template>

<script setup>
import ClearButton from "../../vue-ui/ClearButton"
import {computed, ref} from "vue";
import {useStore} from "vuex";

const name = "Search"
const store = useStore();
let input = ref('')
let placeholder = ref('');

const language = computed(() => store.state.currentLanguage);

const changeUserInput = () => {
    store.commit('changeUserInput', input)
}

const clear = () => {
    input.value = ''
}

if(language.value === 'ru') {
    placeholder.value = 'Введите запрос'
} else {
    placeholder.value = 'Մուտքագրեք հարցում'
}
</script>

<style lang="scss">
.search {
    display: flex;
    justify-content: space-between;
    padding: 73px 0 100px;

    @media screen and (max-width: $mobile) {
        padding: 40px 0 60px;
    }

    &__wrapper {
        position: relative;
        width: 100%;
        height: 60px;

        @media screen and (max-width: $mobile) {
            height: 45px;
        }

        &:before {
            position: absolute;
            content: '';
            width: 23px;
            height: 23px;
            top: 19px;
            left: 28px;
            background-image: url("../../../assets/images/search.svg");
            background-size: cover;
            background-repeat: no-repeat;

            @media screen and (max-width: $mobile) {
                width: 18px;
                height: 18px;
                top: 14px;
                left: 14px;
            }
        }
    }

    &__input {
        width: 100%;
        height: 100%;
        padding: 15px 40px 15px 77px;
        box-sizing: border-box;
        border: 1px solid #2C2C2C;
        border-radius: 8px;
        font-weight: 400;
        font-size: 19px;
        line-height: 32px;
        color: #262626;
        outline: none;

        @media screen and (max-width: $mobile) {
            font-size: 17px;
            padding: 15px 35px 15px 37px;
        }
    }

    &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 240px;
        height: 60px;
        padding: 0;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #FFFFFF;
        background: #3B4C7B;
        border: none;
        border-radius: 8px;
        outline: none;
        cursor: pointer;
    }
}
</style>