<template>
        <ul class="experts-content">
            <li v-for="item in list" class="experts-content__item">
                <img :src="getImgUrl(item.img)" class="experts-content__img" :alt="item.name"/>
                <p class="experts-content__name" v-html="item.name"></p>
                <p class="experts-content__description" v-html="item.description"></p>
            </li>
        </ul>
</template>

<script setup>
import {useStore} from "vuex";
import {computed} from "vue";

const name = "Experts"
const store = useStore();

const data = store.state.currentData;
const list = computed(() => data.experts.list);

const getImgUrl = (pic) => {
    return require('../../../assets/images/photos/' + pic);
};
</script>

<style lang="scss">
.experts-content {
    width: 100%;
    display: flex;
    list-style: none;
    padding: 160px 0 100px;
    margin: 0;
    flex-wrap: wrap;

    @media screen and (max-width: $mobile) {
        padding: 50px 0 100px;
        flex-wrap: wrap;
    }

    &__item {
        width: 380px;
        color: #2C2C2C;
        margin-bottom: 100px;
        margin-right: 20px;

        @media screen and (max-width: $mobile) {
            width: 100%;
            margin-bottom: 40px;
            margin-right: 0;
        }

        &:nth-child(3n) {
            margin-right: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__img {
        display: block;
        width: 180px;
        height: 180px;
        border-radius: 50%;
        margin: 0 auto 34px;
        background-color: #F7F7F7;

        @media screen and (max-width: $mobile) {
            margin-bottom: 25px;
        }
    }

    &__name {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        margin-bottom: 22px;

        @media screen and (max-width: $mobile) {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 15px;
        }
    }

    &__description {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        text-align: center;

        @media screen and (max-width: $mobile) {
            font-size: 16px;
            line-height: 20px;
        }
    }
}
</style>