<template>
    <div class="faq">
        <div class="container">
            <FAQHead :key="language"/>
            <FAQList :key="language"/>
        </div>
    </div>
</template>

<script setup>
import FAQHead from "../components/blocks/FAQ/FAQHead"
import FAQList from "../components/blocks/FAQ/FAQList"
import {useStore} from "vuex";
import {computed} from "vue";

const name = "FAQ";
const store = useStore();

const language = computed(() => store.state.currentLanguage);

</script>

<style scoped>

</style>